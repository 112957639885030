.adm-row {
  display: flex;
  flex-direction: row;
}

.adm-main-page {
  display: flex;
  flex-direction: row;
  background-color: #1d1d1d;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.adm-top-menu {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 1rem;
  height: 5rem;
  display: flex;
  flex-direction: row;
  color: white;
  background-color: #1f1f1f;
  border-radius: 0.3rem;
  z-index: 100;
}

.adm-logo-text {
  margin-top: 0.2rem;
  color: white;
  font-size: 1.6rem;
}

.adm-mark-box {
  margin-left: 0.6rem;
  border: 0.1rem solid #ff9500;
  border-radius: 0.5rem;
  padding: 0.2rem 0.5rem;
  color: white;
  font-size: 0.7rem;
  flex: 0 0;
  cursor: pointer;
}

.adm-mark-box:hover {
  background-color: #2f2f2f;
}

.adm-menu {
  height: 90vh;
  width: 20rem;
  flex-shrink: 0;
}

.adm-menu .row {
  border-right: 0.1rem solid #2f2f2f;
  padding: 0 1.8rem;
  margin-top: 2rem;
  align-items: center;
}

.adm-menu-items {
  margin-top: 2rem;
  padding: 0 1.8rem;
  width: 100%;
}

.adm-menu-item {
  display: flex;
  align-items: center;
  padding: 0.92rem 1.5rem;
  width: 100%;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 0.3rem;
}

.adm-menu-item:hover,
.adm-menu-item.active {
  background-color: #2f2f2f;
}

.adm-menu-item span {
  margin-left: 2rem;
}

.adm-menu-item svg {
  fill: #8f8f8f;
  width: 1.6rem;
  height: 1.6rem;
}

.adm-menu-item:hover svg,
.adm-menu-item.active svg {
  fill: white;
}

.adm-menu-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-left: 2rem;
}

.adm-menu-footer span {
  font-size: 1.2rem;
  color: #8f8f8f;
}

.adm-menu-footer span.enabled {
  color: white;
}

.crypto-switcher {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.crypto-switcher .slider {
  position: relative;
  width: 3.5rem;
  height: 1.4rem;
  margin-top: 0.2rem;
  background-color: #2f2f2f;
  border-radius: 0.7rem;
}

.crypto-switcher .slider:hover {
  background-color: #3f3f3f;
}

.crypto-switcher .slider div {
  position: absolute;
  top: 0.2rem;
  background-color: #8f8f8f;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  transition: 0.2s ease;
}

.crypto-switcher .slider.position-0 div {
  left: 0.2rem;
  background-color: #627eea;
}
.crypto-switcher .slider.position-1 div {
  left: 1.2rem;
  background-color: #ff8c00;
}
.crypto-switcher .slider.position-2 div {
  left: 2.2rem;
  background-color: #41ba97;
}

.crypto-switcher .labels {
  display: flex;
  flex-direction: row;
  margin-top: 0.2rem;
}

.crypto-switcher .labels span:not(:first-child) {
  margin-left: 0.5rem;
}

.adm-settings-button {
  cursor: pointer;
  margin-left: 6.2rem;
}

.adm-settings-button svg {
  fill: #8f8f8f;
  width: 1.8rem;
  height: 1.8rem;
}

.adm-settings-button:hover svg,
.adm-settings-button.active svg {
  fill: white;
}

.adm-page {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-left: 0.5rem;
}

.adm-page-header {
  position: relative;
  margin-top: 2rem;
  width: fit-content;
  height: 3rem;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.adm-page-sub-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  flex-shrink: 0;
}

.adm-page-title {
  color: white;
  font-size: 2rem;
  margin-left: 3rem;
}

.adm-page-header .right {
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.adm-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  padding: 0 0.6rem;
  border: 0.1rem solid #2f2f2f;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 1.2rem;
  color: #8f8f8f;
}

.adm-button:hover {
  background-color: #2f2f2f;
  color: white;
}

.adm-button svg {
  fill: #8f8f8f;
}

.adm-button:hover svg {
  fill: white;
}

.adm-button.web {
  border: none;
  width: fit-content;
  color: white;
}

.adm-button.web div {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #0098db;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adm-button.web div svg {
  fill: white;
  width: 1.3rem;
  height: 1.3rem;
  margin-top: 0.2rem;
}

.adm-button.web span {
  margin-left: 1rem;

  @media (max-width: 700px) {
    display: none;
  }
}

.adm-button.logout {
  width: 7rem;
  margin-left: 0.5rem;
}

.adm-button.logout:hover {
  color: #ef3124;
  border-color: #ef3124;
  background-color: unset;
}

.adm-button.return {
  margin-left: 3rem;
  width: 8rem;
}

.adm-button.return svg {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 1rem;
}

.adm-page-content {
  width: 100%;
  margin-top: 1.6rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.adm-page-content .MuiTableSortLabel-icon {
  color: white !important;
}

.adm-rows-per-page {
  color: white;
  font-size: 1.2rem;
  margin-left: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.adm-rows-per-page-select {
  margin-left: 1rem;
  width: 5rem;
  border: none;
  border-radius: 0.3rem;
}

.adm-search-input {
  height: 3rem;
  border: none;
  border-radius: 0.3rem;
  background-color: #2f2f2f;
  color: #8f8f8f;
}

.adm-search-input svg {
  fill: white;
  width: 1.4rem;
  height: 1.4rem;
}

.adm-search-input input {
  color: white;
}

.adm-search-input:hover {
  background-color: #3f3f3f;
}

.color-true {
  color: #00d151;
}

.color-false {
  color: #ef3124;
}

.color-main {
  color: #0098db;
}

.adm-input-group {
  padding: 2rem;
  border: 0.1rem solid #2f2f2f;
}

.adm-input-group:not(:first-child) {
  border-top: unset;
}

.adm-input-group:first-child {
  border-radius: 0.3rem 0.3rem 0 0;
}

.adm-input-group:last-child {
  border-radius: 0 0 0.3rem 0.3rem;
}

.adm-input-group-caption {
  color: #0098db;
  font-size: 1.3rem;
}

.adm-input-group-btc-commisions {
  margin: 1.5rem 0;
}

.adm-input-control {
  color: white;
  font-size: 1.1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.adm-input-control:nth-child(2) {
  margin-top: 2rem;
}

.adm-input-control label {
  min-width: 14rem;
  width: 20%;
  margin-top: 0.3rem;
}

.adm-input-control input {
  margin-left: 1rem;
  color: white;
  background-color: #2f2f2f;
  border: none;
  border-radius: 0.3rem;
  padding: 0.6rem 1rem;
  min-width: 10rem;
  width: 40%;
}

.adm-input-control input:focus {
  outline: none;
  background-color: white;
  color: black;
}

.adm-input-control input:hover:not(:focus) {
  background-color: #3f3f3f;
}

.adm-input-control-row {
  color: white;
  font-size: 1.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.adm-input-control-row label {
  margin-top: 0.3rem;
}

.adm-input-control-row input {
  margin-left: 1rem;
  color: white;
  background-color: #2f2f2f;
  border: none;
  border-radius: 0.3rem;
  padding: 0.6rem 1rem;
  min-width: 10rem;
  width: 40%;
}

.adm-input-control-row input:focus {
  outline: none;
  background-color: white;
  color: black;
}

.adm-input-control-row input:hover:not(:focus) {
  background-color: #3f3f3f;
}

.adm-select-control-row {
  color: white;
  font-size: 1.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.adm-select-control-row label {
  margin-top: 0.3rem;
}

.adm-select-control-row .select {
  margin-left: 1rem;
  color: white;
  border: none;
  border-radius: 0.3rem;
  padding: 0.6rem 1rem;
  min-width: 10rem;
  width: 40%;
}

.adm-action-button {
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
  background-color: #00d151;
  padding: 0.6rem 1rem;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.adm-action-button.disabled {
  background-color: #8f8f8f;
  cursor: not-allowed;
}

.adm-action-button:not(.disabled):hover {
  background-color: #00af44;
}

.adm-action-button svg {
  fill: white;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
}

.adm-action-button.save {
  width: 17rem;
}

.adm-action-button.login {
  margin-top: 3rem;
  width: 18rem;
}

.adm-withdraw-box,
.adm-fast-deal-box {
  margin-left: 48px;
  margin-bottom: 24px;
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #8f8f8f;
  font-size: 1.2rem;
}

.adm-withdraw-box span.enabled,
.adm-fast-deal-box span.enabled {
  color: white;
}

.adm-fast-deal-box .adm-switcher div {
  background-color: #ef3124;
}

.adm-switcher {
  position: relative;
  width: 3rem;
  height: 1.4rem;
  background-color: #2f2f2f;
  border-radius: 0.7rem;
  cursor: pointer;
}

.adm-switcher:hover {
  background-color: #3f3f3f;
}

.adm-switcher.disabled:hover {
  background-color: #2f2f2f;
  cursor: default;
}

.adm-switcher div {
  position: absolute;
  top: 0.2rem;
  left: 0.3rem;
  background-color: #8f8f8f;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  transition: 0.2s ease;
}

.adm-switcher.disabled div {
  background-color: #8f8f8f;
}

.adm-switcher.enabled div {
  left: 1.7rem;
  background-color: #00d151;
}

.adm-switcher-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.adm-switcher-wrapper__label {
  margin-left: 1rem;
  color: white;
  font-size: 1.2rem;
}

.adm-switcher-wrapper__label-left {
  margin-left: 0rem;
  margin-right: 1rem;
}

.cropped-text {
  max-width: 11vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.adm-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.3rem;
  border-style: hidden;
  box-shadow: 0 0 0 0.1rem #2f2f2f;
  color: white;
  font-size: 1.1rem;
}

.adm-table tr:hover {
  background-color: #272727;
}

.adm-table td {
  border: 0.15rem solid #2f2f2f;
  padding: 1rem;
}

.adm-table td:first-child {
  width: 25%;
  min-width: 12rem;
}

.adm-table-ex tr.MuiTableRow-hover:hover {
  background-color: #272727;
}

.adm-user-verified div {
  background-color: #ef3124;
}

.adm-user-banned div {
  background-color: #00d151;
}

.adm-user-banned.adm-switcher.enabled div {
  background-color: #ef3124;
}

.adm-bid-deleted div {
  background-color: #00d151;
}

.adm-bid-deleted.adm-switcher.enabled div {
  background-color: #ef3124;
}

.adm-user-likes,
.adm-user-dislikes {
  width: 1.5rem;
  height: 1.5rem;
}

.adm-user-likes {
  fill: #00d151;
  margin-right: 0.5rem;
}

.adm-user-dislikes {
  transform: rotate(-180deg);
  fill: #ef3124;
  margin-left: 1rem;
  margin-right: 0.5rem;
}

.adm-page-user-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.adm-page-button {
  background-color: #2f2f2f;
  border: none;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  color: #8f8f8f;
  padding: 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 12rem;
  flex: 0 0;
  margin-bottom: 1rem;
}

.adm-page-button__user-desc {
  flex: 0 auto;
}

.adm_page-copy-button {
  margin-top: 16px;
}

.adm-user__modal-subtitle {
  margin: 16px 0;
}

.adm-user__modal-subtitle_centered {
  text-align: center;
}

.adm-user__modal-subtitle-password {
  font-weight: 700;
}

.adm-page-button:not(:last-child) {
  margin-right: 1rem;
}

.adm-page-button:hover {
  background-color: #3f3f3f;
  color: white;
}

.adm-page-button:focus {
  outline: none;
}

.adm-page-button svg {
  fill: white;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
}

.adm-deal-stage-warn {
  color: #ff8c00;
  align-items: center;
}

.adm-deal-stage-warn svg {
  fill: #ff8c00;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.adm-page-deal-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  width: 50%;
}

.adm-page-deal-buttons button {
  min-width: 20rem;
  margin-bottom: 1rem;
}

.adm-user__modal-close-button-container {
  display: flex;
  justify-content: flex-end;
}

.adm-profit-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.adm-profit-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 0.1rem solid #2f2f2f;
  border-radius: 0.3rem;
  width: 20rem;
  height: 20rem;
  margin-bottom: 2.5rem;
}

.adm-profit-card svg {
  width: 2rem;
  height: 2rem;
}

.adm-profit-card span:nth-child(2) {
  margin-top: 1.8rem;
  color: white;
  font-size: 2.5rem;
}

.adm-profit-card span:nth-child(3) {
  margin-top: 1rem;
  color: #8f8f8f;
  font-size: 1.2rem;
}

.adm-table-popup {
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
}

.adm-table-popup svg {
  fill: #8f8f8f;
  width: 0.7rem;
  height: 0.7rem;
  transition: 0.2s ease;
}

.adm-table-popup:hover,
.adm-table-popup.opened svg {
  fill: white;
}

.adm-table-popup.opened svg {
  transform: rotate(180deg);
}

.adm-table-popup .content {
  display: none;
  position: absolute;
  background-color: #3f3f3f;
  border-radius: 0.3rem;
  padding: 1rem;
  z-index: 33;
  color: white;
}

.adm-table-popup.opened .content {
  display: block;
}

.adm-table-popup .content .close {
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
}

.adm-activations-box {
  width: 22.7rem;
}

.adm-activations-box table {
  margin-top: 1.4rem;
}

.adm-activations-box th {
  font-size: 1.2rem;
  font-weight: normal;
}

.adm-activations-box td {
  font-size: 1.1rem;
}

.adm-activations-box th,
.adm-activations-box td {
  border: 0.1rem solid #4f4f4f;
  padding: 0.4rem 1rem;
}

.adm-transaction-actions {
  right: -0.7rem;
}

.adm-broker-actions .broker-load-logo {
  margin-top: 1.5rem;
}

.adm-broker-actions .broker-load-logo label {
  margin: unset;
  display: block;
}

.adm-button.delete-transaction,
.adm-button.restart-transaction,
.adm-button.broker-action {
  margin-top: 1.5rem;
  width: 10rem;
  color: #8f8f8f;
  border-color: #8f8f8f;
  background-color: unset;
}

.adm-button.select-transaction {
  width: 12rem;
}

.adm-button.delete-transaction:hover,
.adm-button.restart-transaction:hover,
.adm-button.broker-action:hover {
  color: white;
  border-color: white;
}

.adm-merchant-popup input {
  margin-top: 1rem;
  margin-left: 0;
  color: white;
}

.adm-merchant-popup span.percent {
  margin-left: 0.4rem;
  margin-top: 1rem;
}

.adm-merchant-popup input:hover,
.adm-merchant-popup input:focus {
  background-color: #8f8f8f !important;
  color: white;
}

.adm-merchant-popup.text-edit {
  flex-direction: column;
}

.adm-switcher.sky-sale {
  margin-left: 1rem;
  margin-right: 1rem;
}

.adm-switcher.sky-sale div {
  background-color: white;
}
