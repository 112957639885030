$default-font: "Euclid Circular B";
// $file-form: ($file-formats: woff2 woff ttf);
$addr: '../fonts/';
$file-format: woff2 woff;

@mixin font($fname, $fstyle, $fweight, $furl) {
	@font-face {
		font-family: $fname;
		font-style: $fstyle;
		font-weight: $fweight;
		// font-display: swap; // Uncomment to initially display system fonts
		src: local($fname), url($furl) format('woff2');
	}
}

@include font($default-font, normal, 400,  $addr + "EuclidCircularB-Regular/EuclidCircularB-Regular.woff2");
@include font($default-font, normal, 500,  $addr + "EuclidCircularB-Medium/EuclidCircularB-Medium.woff2");
@include font($default-font, normal, 700,  $addr + "EuclidCircularB-Bold/EuclidCircularB-Bold.woff2");
// font-weight helper:

// 100 Thin.woff2
// 200 Extra Light
// 300 Light
// 400 Normal
// 500 Medium
// 600 Semi Bold
// 700 Bold
// 800 Extra Bold
// 900 Ultra Bold
