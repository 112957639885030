@font-face {
  font-family: Euclid Circular B;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../fonts/EuclidCircularB-Regular/EuclidCircularB-Regular.woff2) format('woff2'),
    url(../fonts/EuclidCircularB-Regular/EuclidCircularB-Regular.woff) format('woff');
}
@font-face {
  font-family: Euclid Circular B;
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(../fonts/EuclidCircularB-Medium/EuclidCircularB-Medium.woff2) format('woff2'),
    url(../fonts/EuclidCircularB-Medium/EuclidCircularB-Medium.woff) format('woff');
}
@font-face {
  font-family: Euclid Circular B;
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(../fonts/EuclidCircularB-Bold/EuclidCircularB-Bold.woff2) format('woff2'),
    url(../fonts/EuclidCircularB-Bold/EuclidCircularB-Bold.woff) format('woff');
}
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-arrow-right {
  font-size: 1.3rem;
  width: 1.92308em;
}
.icon-bitcoin {
  font-size: 3rem;
  width: 1em;
}
.icon-check {
  font-size: 2.7rem;
  width: 1em;
}
.icon-chevron-down {
  font-size: 1.8rem;
  width: 1.66667em;
}
.icon-eth {
  font-size: 3rem;
  width: 1em;
}
.icon-eye {
  font-size: 1.6rem;
  width: 1em;
}
.icon-facebook {
  font-size: 1.6rem;
  width: 0.5625em;
}
.icon-instagram {
  font-size: 1.6rem;
  width: 1em;
}
.icon-logo {
  font-size: 19.27rem;
  width: 0.39855em;
}
.icon-telegram {
  font-size: 4rem;
  width: 1em;
}
.icon-twitter {
  font-size: 1.4rem;
  width: 1.14286em;
}
.icon-vk {
  font-size: 1rem;
  width: 1.6em;
}
.icon-youtube {
  font-size: 44.444rem;
  width: 1.12501em;
}
:root {
  --sPT: 2.5rem;
  --sPB: 2.8125rem;
  --sTPB: 1.25rem;
  --blue: #0098db;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ef3124;
  --orange: #fd7e14;
  --yellow: #ff9500;
  --green: #00d151;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0098db;
  --secondary: #828890;
  --success: #00d151;
  --info: #17a2b8;
  --warning: #ff9500;
  --danger: #ef3124;
  --light: #f3f6fa;
  --dark: #000;
  --accent: #00d151;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: 'Euclid Circular B', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
}
*,
:after,
:before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: Euclid Circular B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff;
}
[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}
address {
  font-style: normal;
  line-height: inherit;
}
address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}
dl,
ol,
ul {
  margin-top: 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #0098db;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #00638f;
  text-decoration: underline;
}
a:not([href]),
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New,
    monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  border-style: none;
}
img,
svg {
  vertical-align: middle;
}
svg {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role='button'] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.headerBlock--btn,
.headerBlock__content p,
.headerBlock__group-link,
.headerBlock__label,
.headerBlock__link-btn,
.item-video__title,
.s-contentLogo__lg,
.sForm .section-title p,
.sPeople__title,
.sUsers .section-title__text,
.sWorkingPrinciple__step,
.sWorkingPrinciple__title,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h1,
h1 {
  font-size: 3.25rem;
}
.h2,
h2 {
  font-size: 2.875rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h4,
h4 {
  font-size: 1.5rem;
}
.h5,
.headerBlock__group-link,
.headerBlock__label,
.item-video__title,
.s-contentLogo__lg,
.sPeople__title,
.sWorkingPrinciple__title,
h5 {
  font-size: 1.625rem;
}
.h6,
.headerBlock--btn,
.headerBlock__content p,
.headerBlock__link-btn,
.lead,
.sForm .section-title p,
.sUsers .section-title__text,
.sWorkingPrinciple__step,
h6 {
  font-size: 1.25rem;
}
.lead {
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
}
.display-1,
.display-2 {
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
}
.display-3 {
  font-size: 4.5rem;
}
.display-3,
.display-4 {
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer:before {
  content: '\2014\00A0';
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xs {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}
.container {
  max-width: 480px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.33333%;
}
.offset-2 {
  margin-left: 16.66667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333%;
}
.offset-5 {
  margin-left: 41.66667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333%;
}
.offset-8 {
  margin-left: 66.66667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333%;
}
.offset-11 {
  margin-left: 91.66667%;
}
.custom-select-wrap .chosen-single,
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 2.124rem + 2px);
  padding: 1.062rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #f3f6fa;
  background-clip: padding-box;
  border: 1px solid #f3f6fa;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-select-wrap .chosen-single::-ms-expand,
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.custom-select-wrap .chosen-single:-moz-focusring,
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.custom-select-wrap .chosen-single:focus,
.form-control:focus {
  color: #000;
  background-color: #fff;
  border-color: #e5e5e5;
  outline: 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
.custom-select-wrap .chosen-single::placeholder,
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.custom-select-wrap .chosen-single:disabled,
.custom-select-wrap .chosen-single[readonly],
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.custom-select-wrap input.chosen-single[type='date'],
.custom-select-wrap input.chosen-single[type='datetime-local'],
.custom-select-wrap input.chosen-single[type='month'],
.custom-select-wrap input.chosen-single[type='time'],
input[type='date'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control,
input[type='time'].form-control {
  appearance: none;
}
.custom-select-wrap select.chosen-single:focus::-ms-value,
select.form-control:focus::-ms-value {
  color: #000;
  background-color: #f3f6fa;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(1.062rem + 1px);
  padding-bottom: calc(1.062rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.575rem + 1px);
  padding-bottom: calc(0.575rem + 1px);
  font-size: 1rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1.062rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.custom-select-wrap--currency .form-control-plaintext.chosen-single,
.custom-select-wrap--lang .form-control-plaintext.chosen-single,
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.custom-select-wrap--currency .chosen-single,
.custom-select-wrap--lang .chosen-single,
.form-control-sm {
  height: calc(1.5em + 1.15rem + 2px);
  padding: 0.575rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.custom-select-wrap select.chosen-single[multiple],
.custom-select-wrap select.chosen-single[size],
.custom-select-wrap textarea.chosen-single,
select.form-control[multiple],
select.form-control[size],
textarea.form-control {
  height: auto;
}
.form-group,
.form-wrap .custom-select-wrap {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00d151;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 209, 81, 0.9);
  border-radius: 0.3125rem;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.custom-select-wrap .is-valid.chosen-single,
.custom-select-wrap .was-validated .chosen-single:valid,
.form-control.is-valid,
.was-validated .custom-select-wrap .chosen-single:valid,
.was-validated .form-control:valid {
  border-color: #00d151;
  padding-right: calc(1.5em + 2.124rem);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2300d151' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.531rem) center;
  background-size: calc(0.75em + 1.062rem) calc(0.75em + 1.062rem);
}
.custom-select-wrap .is-valid.chosen-single:focus,
.custom-select-wrap .was-validated .chosen-single:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select-wrap .chosen-single:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #00d151;
  box-shadow: 0 0 0 0.2rem rgba(0, 209, 81, 0.25);
}
.custom-select-wrap .was-validated textarea.chosen-single:valid,
.custom-select-wrap textarea.is-valid.chosen-single,
.was-validated .custom-select-wrap textarea.chosen-single:valid,
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 2.124rem);
  background-position: top calc(0.375em + 0.531rem) right calc(0.375em + 0.531rem);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #00d151;
  padding-right: calc((3em + 6.372rem) / 4 + 1.75rem);
  background: str-replace(
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"),
        '#',
        '%23'
      )
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2300d151' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E")
      #f3f6fa no-repeat center right 1.75rem / calc(0.75em + 1.062rem)
      calc(0.75em + 1.062rem);
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #00d151;
  box-shadow: 0 0 0 0.2rem rgba(0, 209, 81, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #00d151;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #00d151;
}
.custom-control-input.is-valid ~ .custom-control-label:before,
.was-validated .custom-control-input:valid ~ .custom-control-label:before {
  border-color: #00d151;
}
.custom-control-input.is-valid:checked ~ .custom-control-label:before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label:before {
  border-color: #05ff66;
  background-color: #05ff66;
}
.custom-control-input.is-valid:focus ~ .custom-control-label:before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(0, 209, 81, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before,
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label:before,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #00d151;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #00d151;
  box-shadow: 0 0 0 0.2rem rgba(0, 209, 81, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ef3124;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(239, 49, 36, 0.9);
  border-radius: 0.3125rem;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.custom-select-wrap .is-invalid.chosen-single,
.custom-select-wrap .was-validated .chosen-single:invalid,
.form-control.is-invalid,
.was-validated .custom-select-wrap .chosen-single:invalid,
.was-validated .form-control:invalid {
  border-color: #ef3124;
  padding-right: calc(1.5em + 2.124rem);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EF3124'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23EF3124' stroke='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.531rem) center;
  background-size: calc(0.75em + 1.062rem) calc(0.75em + 1.062rem);
}
.custom-select-wrap .is-invalid.chosen-single:focus,
.custom-select-wrap .was-validated .chosen-single:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select-wrap .chosen-single:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #ef3124;
  box-shadow: 0 0 0 0.2rem rgba(239, 49, 36, 0.25);
}
.custom-select-wrap .was-validated textarea.chosen-single:invalid,
.custom-select-wrap textarea.is-invalid.chosen-single,
.was-validated .custom-select-wrap textarea.chosen-single:invalid,
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 2.124rem);
  background-position: top calc(0.375em + 0.531rem) right calc(0.375em + 0.531rem);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #ef3124;
  padding-right: calc((3em + 6.372rem) / 4 + 1.75rem);
  background: str-replace(
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"),
        '#',
        '%23'
      )
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EF3124'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23EF3124' stroke='none'/%3E%3C/svg%3E")
      #f3f6fa no-repeat center right 1.75rem / calc(0.75em + 1.062rem)
      calc(0.75em + 1.062rem);
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #ef3124;
  box-shadow: 0 0 0 0.2rem rgba(239, 49, 36, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #ef3124;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #ef3124;
}
.custom-control-input.is-invalid ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  border-color: #ef3124;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before {
  border-color: #f25e54;
  background-color: #f25e54;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(239, 49, 36, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before,
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label:before,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #ef3124;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #ef3124;
  box-shadow: 0 0 0 0.2rem rgba(239, 49, 36, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
.btn,
.btn-scroll-top,
.headerBlock__btn,
.sAbout__btn,
.sAdvantages__btn,
.sBlog__btn,
.sPeople__btn,
.sPromo__btn,
.sUsers__btn,
.sWorkingPrinciple__btn,
.top-nav__btn {
  display: inline-block;
  font-weight: 400;
  color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1.062rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-scroll-top:hover,
.btn:hover,
.headerBlock__btn:hover,
.sAbout__btn:hover,
.sAdvantages__btn:hover,
.sBlog__btn:hover,
.sPeople__btn:hover,
.sPromo__btn:hover,
.sUsers__btn:hover,
.sWorkingPrinciple__btn:hover,
.top-nav__btn:hover {
  color: #000;
  text-decoration: none;
}
.btn-scroll-top:focus,
.btn.focus,
.btn:focus,
.focus.btn-scroll-top,
.focus.headerBlock__btn,
.focus.sAbout__btn,
.focus.sAdvantages__btn,
.focus.sBlog__btn,
.focus.sPeople__btn,
.focus.sPromo__btn,
.focus.sUsers__btn,
.focus.sWorkingPrinciple__btn,
.focus.top-nav__btn,
.headerBlock__btn:focus,
.sAbout__btn:focus,
.sAdvantages__btn:focus,
.sBlog__btn:focus,
.sPeople__btn:focus,
.sPromo__btn:focus,
.sUsers__btn:focus,
.sWorkingPrinciple__btn:focus,
.top-nav__btn:focus {
  outline: 0;
  box-shadow: none;
}
.btn-scroll-top:disabled,
.btn.disabled,
.btn:disabled,
.disabled.btn-scroll-top,
.disabled.headerBlock__btn,
.disabled.sAbout__btn,
.disabled.sAdvantages__btn,
.disabled.sBlog__btn,
.disabled.sPeople__btn,
.disabled.sPromo__btn,
.disabled.sUsers__btn,
.disabled.sWorkingPrinciple__btn,
.disabled.top-nav__btn,
.headerBlock__btn:disabled,
.sAbout__btn:disabled,
.sAdvantages__btn:disabled,
.sBlog__btn:disabled,
.sPeople__btn:disabled,
.sPromo__btn:disabled,
.sUsers__btn:disabled,
.sWorkingPrinciple__btn:disabled,
.top-nav__btn:disabled {
  opacity: 0.65;
}
.btn-scroll-top:not(:disabled):not(.disabled),
.btn:not(:disabled):not(.disabled),
.headerBlock__btn:not(:disabled):not(.disabled),
.sAbout__btn:not(:disabled):not(.disabled),
.sAdvantages__btn:not(:disabled):not(.disabled),
.sBlog__btn:not(:disabled):not(.disabled),
.sPeople__btn:not(:disabled):not(.disabled),
.sPromo__btn:not(:disabled):not(.disabled),
.sUsers__btn:not(:disabled):not(.disabled),
.sWorkingPrinciple__btn:not(:disabled):not(.disabled),
.top-nav__btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
a.disabled.btn-scroll-top,
a.disabled.headerBlock__btn,
a.disabled.sAbout__btn,
a.disabled.sAdvantages__btn,
a.disabled.sBlog__btn,
a.disabled.sPeople__btn,
a.disabled.sPromo__btn,
a.disabled.sUsers__btn,
a.disabled.sWorkingPrinciple__btn,
a.disabled.top-nav__btn,
fieldset:disabled a.btn,
fieldset:disabled a.btn-scroll-top,
fieldset:disabled a.headerBlock__btn,
fieldset:disabled a.sAbout__btn,
fieldset:disabled a.sAdvantages__btn,
fieldset:disabled a.sBlog__btn,
fieldset:disabled a.sPeople__btn,
fieldset:disabled a.sPromo__btn,
fieldset:disabled a.sUsers__btn,
fieldset:disabled a.sWorkingPrinciple__btn,
fieldset:disabled a.top-nav__btn {
  pointer-events: none;
}
.btn-primary,
.sBlog__btn,
.top-nav__btn {
  color: #fff;
  background-color: #0098db;
  border-color: #0098db;
}
.btn-primary:hover,
.sBlog__btn:hover,
.top-nav__btn:hover {
  color: #fff;
  background-color: #007db5;
  border-color: #0075a8;
}
.btn-primary.focus,
.btn-primary:focus,
.focus.sBlog__btn,
.focus.top-nav__btn,
.sBlog__btn:focus,
.top-nav__btn:focus {
  color: #fff;
  background-color: #007db5;
  border-color: #0075a8;
  box-shadow: 0 0 0 0.2rem rgba(38, 167, 224, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled,
.disabled.sBlog__btn,
.disabled.top-nav__btn,
.sBlog__btn:disabled,
.top-nav__btn:disabled {
  color: #fff;
  background-color: #0098db;
  border-color: #0098db;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.sBlog__btn:not(:disabled):not(.disabled).active,
.sBlog__btn:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.show > .dropdown-toggle.sBlog__btn,
.show > .dropdown-toggle.top-nav__btn,
.top-nav__btn:not(:disabled):not(.disabled).active,
.top-nav__btn:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0075a8;
  border-color: #006c9b;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.sBlog__btn:not(:disabled):not(.disabled).active:focus,
.sBlog__btn:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.show > .dropdown-toggle.sBlog__btn:focus,
.show > .dropdown-toggle.top-nav__btn:focus,
.top-nav__btn:not(:disabled):not(.disabled).active:focus,
.top-nav__btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 167, 224, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #828890;
  border-color: #828890;
}
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover {
  color: #fff;
  background-color: #6f757d;
  border-color: #696f76;
}
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 154, 161, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #828890;
  border-color: #828890;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #696f76;
  border-color: #63686f;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 154, 161, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #00d151;
  border-color: #00d151;
}
.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
  color: #fff;
  background-color: #00ab42;
  border-color: #009e3d;
}
.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 216, 107, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #00d151;
  border-color: #00d151;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #009e3d;
  border-color: #009138;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 216, 107, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info.focus,
.btn-info:focus,
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-warning,
.sUsers__btn {
  color: #212529;
  background-color: #ff9500;
  border-color: #ff9500;
}
.btn-warning:hover,
.sUsers__btn:hover {
  color: #fff;
  background-color: #d97f00;
  border-color: #c70;
}
.btn-warning.focus,
.btn-warning:focus,
.focus.sUsers__btn,
.sUsers__btn:focus {
  color: #fff;
  background-color: #d97f00;
  border-color: #c70;
  box-shadow: 0 0 0 0.2rem rgba(222, 132, 6, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled,
.disabled.sUsers__btn,
.sUsers__btn:disabled {
  color: #212529;
  background-color: #ff9500;
  border-color: #ff9500;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle,
.show > .dropdown-toggle.sUsers__btn,
.sUsers__btn:not(:disabled):not(.disabled).active,
.sUsers__btn:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #c70;
  border-color: #bf7000;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus,
.show > .dropdown-toggle.sUsers__btn:focus,
.sUsers__btn:not(:disabled):not(.disabled).active:focus,
.sUsers__btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 132, 6, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #ef3124;
  border-color: #ef3124;
}
.btn-danger.focus,
.btn-danger:focus,
.btn-danger:hover {
  color: #fff;
  background-color: #dd1d10;
  border-color: #d11c0f;
}
.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 80, 69, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #ef3124;
  border-color: #ef3124;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d11c0f;
  border-color: #c51a0e;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 80, 69, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f3f6fa;
  border-color: #f3f6fa;
}
.btn-light.focus,
.btn-light:focus,
.btn-light:hover {
  color: #212529;
  background-color: #d8e2ef;
  border-color: #cfdbeb;
}
.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 215, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f3f6fa;
  border-color: #f3f6fa;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #cfdbeb;
  border-color: #c6d4e7;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 215, 219, 0.5);
}
.btn-dark,
.btn-dark.focus,
.btn-dark:focus,
.btn-dark:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled,
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-accent {
  color: #fff;
  background-color: #00d151;
  border-color: #00d151;
}
.btn-accent.focus,
.btn-accent:focus,
.btn-accent:hover {
  color: #fff;
  background-color: #00ab42;
  border-color: #009e3d;
}
.btn-accent.focus,
.btn-accent:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 216, 107, 0.5);
}
.btn-accent.disabled,
.btn-accent:disabled {
  color: #fff;
  background-color: #00d151;
  border-color: #00d151;
}
.btn-accent:not(:disabled):not(.disabled).active,
.btn-accent:not(:disabled):not(.disabled):active,
.show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #009e3d;
  border-color: #009138;
}
.btn-accent:not(:disabled):not(.disabled).active:focus,
.btn-accent:not(:disabled):not(.disabled):active:focus,
.show > .btn-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 216, 107, 0.5);
}
.btn-outline-primary {
  color: #0098db;
  border-color: #0098db;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0098db;
  border-color: #0098db;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 152, 219, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #0098db;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0098db;
  border-color: #0098db;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 152, 219, 0.5);
}
.btn-outline-secondary {
  color: #828890;
  border-color: #828890;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #828890;
  border-color: #828890;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 136, 144, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #828890;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #828890;
  border-color: #828890;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 136, 144, 0.5);
}
.btn-outline-success {
  color: #00d151;
  border-color: #00d151;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #00d151;
  border-color: #00d151;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 209, 81, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #00d151;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #00d151;
  border-color: #00d151;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 209, 81, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ff9500;
  border-color: #ff9500;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ff9500;
  border-color: #ff9500;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 149, 0, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ff9500;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ff9500;
  border-color: #ff9500;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 149, 0, 0.5);
}
.btn-outline-danger {
  color: #ef3124;
  border-color: #ef3124;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ef3124;
  border-color: #ef3124;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 49, 36, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #ef3124;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ef3124;
  border-color: #ef3124;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 49, 36, 0.5);
}
.btn-outline-light {
  color: #f3f6fa;
  border-color: #f3f6fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f3f6fa;
  border-color: #f3f6fa;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 246, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f3f6fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f3f6fa;
  border-color: #f3f6fa;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 246, 250, 0.5);
}
.btn-outline-dark {
  color: #000;
  border-color: #000;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #000;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-accent {
  color: #00d151;
  border-color: #00d151;
}
.btn-outline-accent:hover {
  color: #fff;
  background-color: #00d151;
  border-color: #00d151;
}
.btn-outline-accent.focus,
.btn-outline-accent:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 209, 81, 0.5);
}
.btn-outline-accent.disabled,
.btn-outline-accent:disabled {
  color: #00d151;
  background-color: transparent;
}
.btn-outline-accent:not(:disabled):not(.disabled).active,
.btn-outline-accent:not(:disabled):not(.disabled):active,
.show > .btn-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #00d151;
  border-color: #00d151;
}
.btn-outline-accent:not(:disabled):not(.disabled).active:focus,
.btn-outline-accent:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 209, 81, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #0098db;
  text-decoration: none;
}
.btn-link:hover {
  color: #00638f;
}
.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
  text-decoration: underline;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-sm,
.top-nav__btn {
  padding: 0.575rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block,
.btn-scroll-top,
.sAdvantages__btn,
.sBlog__btn,
.sUsers__btn,
.sWorkingPrinciple__btn {
  display: block;
  width: 100%;
}
.btn-block + .btn-block,
.btn-block + .btn-scroll-top,
.btn-block + .sAdvantages__btn,
.btn-block + .sBlog__btn,
.btn-block + .sUsers__btn,
.btn-block + .sWorkingPrinciple__btn,
.btn-scroll-top + .btn-block,
.btn-scroll-top + .btn-scroll-top,
.btn-scroll-top + .sAdvantages__btn,
.btn-scroll-top + .sBlog__btn,
.btn-scroll-top + .sUsers__btn,
.btn-scroll-top + .sWorkingPrinciple__btn,
.sAdvantages__btn + .btn-block,
.sAdvantages__btn + .btn-scroll-top,
.sAdvantages__btn + .sAdvantages__btn,
.sAdvantages__btn + .sBlog__btn,
.sAdvantages__btn + .sUsers__btn,
.sAdvantages__btn + .sWorkingPrinciple__btn,
.sBlog__btn + .btn-block,
.sBlog__btn + .btn-scroll-top,
.sBlog__btn + .sAdvantages__btn,
.sBlog__btn + .sBlog__btn,
.sBlog__btn + .sUsers__btn,
.sBlog__btn + .sWorkingPrinciple__btn,
.sUsers__btn + .btn-block,
.sUsers__btn + .btn-scroll-top,
.sUsers__btn + .sAdvantages__btn,
.sUsers__btn + .sBlog__btn,
.sUsers__btn + .sUsers__btn,
.sUsers__btn + .sWorkingPrinciple__btn,
.sWorkingPrinciple__btn + .btn-block,
.sWorkingPrinciple__btn + .btn-scroll-top,
.sWorkingPrinciple__btn + .sAdvantages__btn,
.sWorkingPrinciple__btn + .sBlog__btn,
.sWorkingPrinciple__btn + .sUsers__btn,
.sWorkingPrinciple__btn + .sWorkingPrinciple__btn {
  margin-top: 0.5rem;
}
input.btn-scroll-top[type='button'],
input.btn-scroll-top[type='reset'],
input.btn-scroll-top[type='submit'],
input.sAdvantages__btn[type='button'],
input.sAdvantages__btn[type='reset'],
input.sAdvantages__btn[type='submit'],
input.sBlog__btn[type='button'],
input.sBlog__btn[type='reset'],
input.sBlog__btn[type='submit'],
input.sUsers__btn[type='button'],
input.sUsers__btn[type='reset'],
input.sUsers__btn[type='submit'],
input.sWorkingPrinciple__btn[type='button'],
input.sWorkingPrinciple__btn[type='reset'],
input.sWorkingPrinciple__btn[type='submit'],
input[type='button'].btn-block,
input[type='reset'].btn-block,
input[type='submit'].btn-block {
  width: 100%;
}
.breadcrumb {
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.3125rem;
}
.breadcrumb,
.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item:before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '/';
}
.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: underline;
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.breadcrumb .active a {
  color: #6c757d;
  pointer-events: none;
}
.pagination,
.pagination-wrapper ul {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3125rem;
}
.page-link,
.pagination-wrapper li .page-numbers {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0098db;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover,
.pagination-wrapper li .page-numbers:hover {
  z-index: 2;
  color: #00638f;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus,
.pagination-wrapper li .page-numbers:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}
.page-item:first-child .page-link,
.page-item:first-child .pagination-wrapper li .page-numbers,
.pagination-wrapper li .page-item:first-child .page-numbers,
.pagination-wrapper li:first-child .page-link,
.pagination-wrapper li:first-child .page-numbers {
  margin-left: 0;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.page-item:last-child .page-link,
.page-item:last-child .pagination-wrapper li .page-numbers,
.pagination-wrapper li .page-item:last-child .page-numbers,
.pagination-wrapper li:last-child .page-link,
.pagination-wrapper li:last-child .page-numbers {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.page-item.active .page-link,
.page-item.active .pagination-wrapper li .page-numbers,
.pagination-wrapper li.active .page-link,
.pagination-wrapper li.active .page-numbers,
.pagination-wrapper li .page-item.active .page-numbers {
  z-index: 3;
  color: #fff;
  background-color: #0098db;
  border-color: #0098db;
}
.page-item.disabled .page-link,
.page-item.disabled .pagination-wrapper li .page-numbers,
.pagination-wrapper li.disabled .page-link,
.pagination-wrapper li.disabled .page-numbers,
.pagination-wrapper li .page-item.disabled .page-numbers {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link,
.pagination-lg .pagination-wrapper li .page-numbers,
.pagination-wrapper li .pagination-lg .page-numbers {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link,
.pagination-lg .page-item:first-child .pagination-wrapper li .page-numbers,
.pagination-lg .pagination-wrapper li:first-child .page-link,
.pagination-lg .pagination-wrapper li:first-child .page-numbers,
.pagination-wrapper .pagination-lg li:first-child .page-link,
.pagination-wrapper .pagination-lg li:first-child .page-numbers,
.pagination-wrapper li .pagination-lg .page-item:first-child .page-numbers {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link,
.pagination-lg .page-item:last-child .pagination-wrapper li .page-numbers,
.pagination-lg .pagination-wrapper li:last-child .page-link,
.pagination-lg .pagination-wrapper li:last-child .page-numbers,
.pagination-wrapper .pagination-lg li:last-child .page-link,
.pagination-wrapper .pagination-lg li:last-child .page-numbers,
.pagination-wrapper li .pagination-lg .page-item:last-child .page-numbers {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link,
.pagination-sm .pagination-wrapper li .page-numbers,
.pagination-wrapper li .pagination-sm .page-numbers {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link,
.pagination-sm .page-item:first-child .pagination-wrapper li .page-numbers,
.pagination-sm .pagination-wrapper li:first-child .page-link,
.pagination-sm .pagination-wrapper li:first-child .page-numbers,
.pagination-wrapper .pagination-sm li:first-child .page-link,
.pagination-wrapper .pagination-sm li:first-child .page-numbers,
.pagination-wrapper li .pagination-sm .page-item:first-child .page-numbers {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link,
.pagination-sm .page-item:last-child .pagination-wrapper li .page-numbers,
.pagination-sm .pagination-wrapper li:last-child .page-link,
.pagination-sm .pagination-wrapper li:last-child .page-numbers,
.pagination-wrapper .pagination-sm li:last-child .page-link,
.pagination-wrapper .pagination-sm li:last-child .page-numbers,
.pagination-wrapper li .pagination-sm .page-item:last-child .page-numbers {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.pagination-wrapper li .current {
  z-index: 1;
  color: #fff;
  background-color: #0098db;
  border-color: #0098db;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #0098db !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0075a8 !important;
}
.bg-secondary {
  background-color: #828890 !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #696f76 !important;
}
.bg-success {
  background-color: #00d151 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #009e3d !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ff9500 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #c70 !important;
}
.bg-danger {
  background-color: #ef3124 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #d11c0f !important;
}
.bg-light {
  background-color: #f3f6fa !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #cfdbeb !important;
}
.bg-dark,
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #000 !important;
}
.bg-accent {
  background-color: #00d151 !important;
}
a.bg-accent:focus,
a.bg-accent:hover,
button.bg-accent:focus,
button.bg-accent:hover {
  background-color: #009e3d !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #0098db !important;
}
.border-secondary {
  border-color: #828890 !important;
}
.border-success {
  border-color: #00d151 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ff9500 !important;
}
.border-danger {
  border-color: #ef3124 !important;
}
.border-light {
  border-color: #f3f6fa !important;
}
.border-dark {
  border-color: #000 !important;
}
.border-accent {
  border-color: #00d151 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.3125rem !important;
}
.rounded-top {
  border-top-left-radius: 0.3125rem !important;
}
.rounded-right,
.rounded-top {
  border-top-right-radius: 0.3125rem !important;
}
.rounded-bottom,
.rounded-right {
  border-bottom-right-radius: 0.3125rem !important;
}
.rounded-bottom,
.rounded-left {
  border-bottom-left-radius: 0.3125rem !important;
}
.rounded-left {
  border-top-left-radius: 0.3125rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix:after {
  display: block;
  clear: both;
  content: '';
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive:before {
  display: block;
  content: '';
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9:before {
  padding-top: 42.85714%;
}
.embed-responsive-16by9:before {
  padding-top: 56.25%;
}
.embed-responsive-4by3:before {
  padding-top: 75%;
}
.embed-responsive-1by1:before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
.user-select-all {
  user-select: all !important;
}
.user-select-auto {
  user-select: auto !important;
}
.user-select-none {
  user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-top {
  top: 0;
}
.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  bottom: 0;
}
@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.stretched-link:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: transparent;
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New,
    monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #0098db !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #00638f !important;
}
.sWorkingPrinciple__slide p,
.text-secondary {
  color: #828890 !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #5d6269 !important;
}
.text-success {
  color: #00d151 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #008533 !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}
.text-warning {
  color: #ff9500 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #b36800 !important;
}
.text-danger {
  color: #ef3124 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #b9180e !important;
}
.text-light {
  color: #f3f6fa !important;
}
a.text-light:focus,
a.text-light:hover {
  color: #bdcee4 !important;
}
.text-dark,
a.text-dark:focus,
a.text-dark:hover {
  color: #000 !important;
}
.text-accent {
  color: #00d151 !important;
}
a.text-accent:focus,
a.text-accent:hover {
  color: #008533 !important;
}
.sBlog__item,
.sBlog__title a,
.text-body {
  color: #000 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
html {
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}
.pixel-perfect {
  top: 0;
  position: absolute;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  display: block;
  pointer-events: none;
  z-index: 1000;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-position: center 0;
  opacity: 0;
  opacity: 0.7;
}
a:hover {
  color: #0098db;
}
body,
html {
  font-size: 12px !important;
}
.section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  padding-top: var(--sPT);
  padding-bottom: var(--sPB);
}
h2 {
  font-weight: 700;
  line-height: 1.26087;
}
.section-title {
  padding-bottom: var(--sTPB);
}
body {
  min-width: 320px;
  position: relative;
}
body:before {
  content: '';
  height: 100%;
  transition: all 0.2s;
  background-color: #000;
  opacity: 0;
  z-index: -1;
}
body.fixed,
body:before {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
}
body.fixed {
  overflow: hidden;
  z-index: 999;
}
body.fixed:before {
  z-index: 999;
  opacity: 0.76;
}
.mono {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New,
    monospace;
}
.small {
  font-size: 0.875rem;
}
.form-wrap__policy a,
.tdn,
.tdu {
  text-decoration: none !important;
}
.form-wrap__policy a,
.tdu {
  color: #0098db !important;
  border-bottom: 1px solid;
}
.form-wrap__policy a:hover,
.tdu:hover {
  border-color: transparent;
}
.ttu {
  text-transform: uppercase;
}
.fw-300 {
  font-weight: 300;
}
.fw-500 {
  font-weight: 500;
}
.fw-900 {
  font-weight: 900;
}
.row--small {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
.row--small > * {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.main-wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
.slick-slide img {
  display: inline;
}
.image-source-link {
  color: #98c3d1;
}
.slick-slide {
  outline: 0 !important;
}
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
}
.slick-arrow i {
  line-height: 1;
}
.slick-arrow.l {
  left: 0;
}
.slick-arrow.l svg {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.slick-arrow.r {
  right: 0;
}
.slick-arrow svg {
  width: 17px;
  height: auto;
}
.strong {
  font-weight: 700;
}
ul {
  padding-left: 0;
}
.res-i,
picture img {
  max-width: 100%;
  height: auto;
}
.img-bg {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.img-bg img {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
.bgs {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.table {
  display: table;
}
.tr {
  display: table-row;
}
.td {
  display: table-cell;
}
.tabs__btn:hover {
  cursor: pointer;
}
.tabs__content {
  display: none;
}
.tabs__content.active {
  display: block;
}
.custom-select-wrap select.chosen-single,
select.form-control {
  background-image: url(../img/svg/chevron-down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  appearance: none !important;
}
input[type='number'] {
  -moz-appearance: textfield;
}
select {
  -webkit-appearance: none;
}
select::-ms-expand {
  display: none;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.invisible {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
.control-wrap {
  position: relative;
}
.swiper-button-hand {
  width: 45px;
  height: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  z-index: 300;
  outline: none !important;
  background-image: none;
  margin: 0;
  position: absolute;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}
.swiper-button-hand-next {
  right: 0;
}
.swiper-button-hand-prev {
  left: 0;
}
.swiper-pagination {
  width: 100%;
  text-align: center;
}
.swiper-pagination-bullet {
  opacity: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #828890;
  outline: none !important;
  transition: all 0.2s;
  margin-left: 0.5rem;
  margin-right: 0.3125rem;
}
.swiper-pagination-bullet-active,
.swiper-pagination-bullet:hover {
  background-color: #0098db;
}
picture {
  display: block;
}
picture img.object-fit-js {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
}
.object-fit-js img,
img.img-bg {
  object-fit: cover;
  object-position: top;
  font-family: 'object-fit: cover; object-position: top;';
}
a {
  transition: all 0.2s;
}
.picture-bg,
img.img-bg {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.bg-wrap {
  position: relative;
  z-index: 2;
}
.browsehappy {
  position: absolute;
  width: 100%;
  z-index: 1000;
  background: #000;
  min-height: 100px;
  font-size: 20px;
  color: #fff;
}
iframe {
  border: none;
}
.btn,
.btn-scroll-top,
.headerBlock__btn,
.sAbout__btn,
.sAdvantages__btn,
.sBlog__btn,
.sPeople__btn,
.sPromo__btn,
.sUsers__btn,
.sWorkingPrinciple__btn,
.top-nav__btn {
  font-weight: 500;
}
.btn-warning,
.sUsers__btn {
  color: #fff !important;
  box-shadow: 0 3px 0 #f06a0b, 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 0.3125rem;
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.btn-warning:after,
.sUsers__btn:after {
  content: '';
  display: block;
  width: 30px;
  height: 300px;
  margin-left: 60px;
  background: #fff;
  opacity: 0.3;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.7) 0,
    #fff 50%,
    hsla(0, 0%, 100%, 0.7)
  );
  position: absolute;
  left: -40px;
  top: -150px;
  z-index: -1;
  transform: rotate(45deg);
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 50ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  to {
    left: 110%;
    margin-left: 80px;
  }
}
.btn-warning:hover,
.sUsers__btn:hover {
  background: #ffa318;
  border-color: #ffa318;
}
.btn-warning:focus,
.sUsers__btn:focus {
  background: #ffa318 !important;
  border-color: #ffa318 !important;
  box-shadow: 0 0 0 #f06a0b, 0 0 0 rgba(0, 0, 0, 0.25) !important;
}
.btn-primary,
.sBlog__btn,
.top-nav__btn {
  box-shadow: none !important;
}
.btn-outline,
.btn-scroll-top,
.sAbout__btn:not(.btn-warning):not(.sUsers__btn):not(.btn-primary):not(.sBlog__btn):not(.top-nav__btn),
.sAdvantages__btn:not(.btn-warning):not(.sUsers__btn):not(.btn-primary):not(.sBlog__btn):not(.top-nav__btn) {
  border: 2px solid #0098db;
  color: #0098db;
  transition: all 0.2s;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.btn-outline:hover,
.btn-scroll-top:hover,
.sAbout__btn:hover:not(.btn-warning):not(.sUsers__btn):not(.btn-primary):not(.sBlog__btn):not(.top-nav__btn),
.sAdvantages__btn:hover:not(.btn-warning):not(.sUsers__btn):not(.btn-primary):not(.sBlog__btn):not(.top-nav__btn) {
  background-color: #00aaf4;
  border-color: #00aaf4;
  color: #fff;
}
.btn-primary:hover,
.sBlog__btn:hover,
.top-nav__btn:hover {
  border-color: #00aaf4 !important;
  background: #00aaf4 !important;
}
.btn-scroll-top {
  margin-left: auto;
  margin-right: auto;
  width: 12.5rem;
  margin-bottom: 1.875rem;
}
.btn-scroll-top svg {
  position: relative;
  bottom: 0.125rem;
  margin-right: 0.6875rem;
  width: 1.125rem;
  fill: currentColor;
}
.footer {
  text-align: center;
  padding: 2.5rem 0;
  background: #f3f6fa;
}
.footer .row {
  align-items: center;
}
.footer__col {
  text-align: center;
}
.footer__logo {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none !important;
  margin-bottom: 1.875rem;
  justify-content: center;
  font-size: 1.125rem;
}
.footer__logo .icon {
  color: #0098db;
  margin-right: 0.625rem;
  font-size: 2rem;
}
.footer__logo img {
  flex: 0 0 0.75rem;
  max-width: 0.75rem;
  margin-right: 0.625rem;
}
.footer__text {
  color: #828891;
  margin-bottom: 1rem;
}
.input-pref {
  padding-right: 3.125rem;
}
.modal-win__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 26rem;
}
.form-wrap__title-top {
  font-size: 1.625rem;
  margin-bottom: 0.625rem;
  font-weight: 500;
}
.form-wrap__text {
  margin-bottom: 1.875rem;
}
.form-wrap__title {
  margin-bottom: 8px;
  display: block;
}
.form-wrap__input-wrap {
  position: relative;
}
.form-wrap__input-wrap label {
  margin-bottom: 0;
}
.form-wrap__preff {
  position: absolute;
  color: #828891;
  right: 1rem;
  top: 1.125rem;
  pointer-events: none;
}
.form-wrap__input {
  resize: none;
}
.form-wrap__btn {
  cursor: pointer;
}
.form-wrap label {
  width: 100%;
  display: block;
}
.form-wrap__policy {
  padding-top: 10px;
}
.parsley-error {
  border-image: none;
  border: 2px solid red !important;
  color: red;
  outline: 0 !important;
}
.parsley-success {
  border: 2px solid #00d600 !important;
}
.parsley-errors-list {
  color: red;
}
.custom-input {
  display: block;
  margin: auto auto 1.25rem;
  cursor: pointer;
  position: relative;
  padding-left: 3.125rem;
  display: inline-block;
}
.custom-input__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
.custom-input__lab {
  left: 0;
  display: block;
  top: -0.1875rem;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.3125rem;
  background-color: #f3f6fa;
  transition: all 0.2s;
  position: absolute;
  margin-right: 10px;
}
.custom-input__input[type='checkbox'] + .custom-input__lab:after,
.custom-input__lab:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.custom-input__input[type='checkbox'] + .custom-input__lab:after {
  background-image: url(../img/svg/check.svg);
  width: 1.875rem;
  height: 1.875rem;
  background-size: 0.9375rem 0.6875rem;
  background-position: 50%;
  background-repeat: no-repeat;
}
.custom-input__input[type='radio'] + .custom-input__lab,
.custom-input__input[type='radio'] + .custom-input__lab:after {
  border-radius: 50%;
}
.custom-input__input:checked + .custom-input__lab:after {
  opacity: 1;
}
.select2-container--default {
  display: block;
  outline: 0 !important;
}
.select2-container--default .select2-selection--single {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid;
  height: auto;
  font-size: 1.5rem;
  outline: 0 !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #00d151 transparent transparent;
  border-style: solid;
  border-width: 7px 7px 0;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  white-space: normal;
  padding-left: 0;
  padding-bottom: 5px;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #00d151;
  border-width: 0 7px 7px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #00d151;
}
.canvas-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.headerBlock {
  background-color: #192d46;
  position: relative;
  z-index: 2;
  padding-top: 6.25rem;
  padding-bottom: 2.8125rem;
  padding-top: 7.5rem !important;
}
.headerBlock h1 {
  line-height: 1.26923;
}
.headerBlock__scroll-down {
  position: absolute;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  bottom: 2.5rem;
  color: #fff;
  cursor: pointer;
  padding: 0.625rem;
}
.headerBlock__scroll-down .icon {
  font-size: 1rem;
}
.headerBlock h1 {
  text-align: center;
  color: #fff;
  font-weight: 700;
  margin-bottom: 1.25rem;
  margin-top: auto;
}
.headerBlock__center {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
.headerBlock__inner {
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  margin-bottom: 3.125rem;
  max-width: 71.25rem;
}
.headerBlock .form-wrap {
  color: #000;
  text-align: left;
}
.headerBlock__title {
  font-size: 1.625rem;
  font-weight: 500;
}
.headerBlock__group {
  margin-bottom: 1.25rem;
}
.headerBlock__group-title {
  color: #828890;
  margin-right: 0.3125rem;
}
.headerBlock .form-wrap {
  border-radius: 10px;
}
.headerBlock .form-wrap__text-center {
  margin-bottom: 1.375rem;
}
.headerBlock .form-wrap .headerBlock__group {
  margin-bottom: 0.625rem;
}
.headerBlock__inner .headerBlock__group-title {
  margin-bottom: 0.4375rem;
}
.headerBlock .btn,
.headerBlock .btn-scroll-top,
.headerBlock .headerBlock__btn,
.headerBlock .sAbout__btn,
.headerBlock .sAdvantages__btn,
.headerBlock .sBlog__btn,
.headerBlock .sPeople__btn,
.headerBlock .sPromo__btn,
.headerBlock .sUsers__btn,
.headerBlock .sWorkingPrinciple__btn,
.headerBlock .top-nav__btn {
  height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem;
  margin-bottom: 1.25rem;
}
.headerBlock .btn-scroll-top img,
.headerBlock .btn img,
.headerBlock .headerBlock__btn img,
.headerBlock .sAbout__btn img,
.headerBlock .sAdvantages__btn img,
.headerBlock .sBlog__btn img,
.headerBlock .sPeople__btn img,
.headerBlock .sPromo__btn img,
.headerBlock .sUsers__btn img,
.headerBlock .sWorkingPrinciple__btn img,
.headerBlock .top-nav__btn img {
  margin-left: 0.8125rem;
  position: relative;
  top: 0.1875rem;
}
.headerBlock__slide {
  width: auto;
  opacity: 0.5;
  transition: all 0.2s;
}
.headerBlock__slide:hover {
  opacity: 1;
}
.headerBlock .swiper-wrapper {
  align-items: center;
}
.headerBlock__container {
  max-width: 100% !important;
  margin-top: auto;
}
.headerBlock__slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
}
.headerBlock__slide img {
  max-height: 100%;
}
.headerBlock .container {
  position: relative;
}
.headerBlock__content {
  max-width: 33.4375rem;
  margin-left: auto;
  padding-bottom: 3.125rem;
  text-align: center;
  margin-right: auto;
  text-align: left;
}
.headerBlock__content picture {
  margin-bottom: 1.5rem;
  text-align: center;
}
.headerBlock__content h1 {
  margin-bottom: 1.625rem !important;
}
.headerBlock__content p {
  margin-bottom: 25px;
  text-align: center;
  color: #a8b5c6;
  line-height: 1.5;
  font-weight: 400;
}
.headerBlock__btn-wrap .row {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem;
}
.headerBlock__btn-wrap .row > * {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}
.headerBlock__btn {
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 240px;
}
.headerBlock__btn.btn-warning,
.headerBlock__btn.sUsers__btn {
  height: 3.625rem;
}
.headerBlock .btn-outline-white {
  border: 1px solid #3c4c62;
  color: #fff;
}
.headerBlock .btn-outline-white:hover {
  background-color: #fff;
  color: #3c4c62;
}
.headerBlock--minh1 {
  min-height: 1px !important;
  padding-bottom: calc(var(--sPB) - 1.25rem);
}
.headerBlock--minh1 p {
  margin-bottom: 1.875rem;
}
.headerBlock--btn {
  color: #a8b5c6;
  font-weight: 400;
  line-height: 1.5;
}
.headerBlock__link-btn {
  min-width: 9.375rem;
  border-radius: 0.3125rem;
  border: 2px solid #3c4c62;
  display: block;
  text-align: center;
  padding: 1.0625rem 1.875rem;
  color: #fff !important;
  text-decoration: none !important;
  margin-bottom: 10px;
}
.headerBlock__link-btn:not(.headerBlock__link-btn--active):hover {
  background-color: #fff;
  border-color: #fff;
  color: #3c4c62 !important;
}
.headerBlock__link-btn--active {
  background-color: #0098db !important;
  border-color: #0098db !important;
  color: #fff !important;
}
.headerBlock textarea {
  min-width: 7.5rem;
}
.headerBlock__contact-wrap {
  margin-bottom: 30px;
  font-size: 1rem;
}
.headerBlock__group {
  font-size: 1rem;
}
.headerBlock__group-title {
  font-weight: 500;
  margin-bottom: 0.9375rem;
}
.headerBlock__group-link {
  display: inline-flex;
  align-items: center;
  color: #fff !important;
  margin-bottom: 0;
  font-weight: 400;
}
.headerBlock__icon-wrap {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 50%;
  flex: 0 0 2.1875rem;
  margin-right: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerBlock__contact p {
  padding-bottom: 1.25rem;
  margin-bottom: 1.875rem;
  padding-top: 0.3125rem;
  color: #a8b5c6;
}
.headerBlock a {
  text-decoration: none !important;
}
.headerBlock__label {
  color: #ff9500;
  margin-bottom: 1.875rem;
  line-height: 1.26923;
  text-align: center;
}
.headerBlock picture img {
  max-height: 70vh;
}
.headerBlock__btn .icon {
  font-size: 2.5rem;
}
.soc {
  display: flex;
  justify-content: center;
  margin: 0 -0.3125rem 1.4375rem;
  flex-wrap: wrap;
}
.soc .icon {
  font-size: 16px;
  color: #fff;
}
.soc__item {
  text-decoration: none !important;
  margin: 0 0.3125rem;
  vertical-align: middle;
  transition: all 0.2s;
  display: block;
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.soc__item--vk {
  background: #4a76a8;
}
.soc__item--vk .icon {
  font-size: 10px;
}
.soc__item--vk:hover {
  background-color: #000;
  background: #3a5d85;
}
.soc__item--instagram {
  background: #e94754;
}
.soc__item--instagram:hover {
  background: #e21b2b;
}
.soc__item--facebook {
  background: #1877f2;
}
.soc__item--facebook:hover {
  background: #0b5fcc;
}
.soc__item--twitter {
  background: #03a9f4;
}
.soc__item--twitter .icon {
  font-size: 13px;
}
.soc__item--twitter:hover {
  background: #0286c2;
}
.soc__item--youtube {
  background: red;
}
.soc__item--youtube .icon {
  font-size: 14px;
}
.soc__item--youtube:hover {
  background: #c00;
}
.counter {
  counter-reset: num;
}
.counter__item {
  position: relative;
}
.counter__item:before {
  content: counter(num);
  counter-increment: num;
}
.logo-main {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
  text-decoration: none !important;
  max-width: 200px;
  color: #0098db;
  justify-content: center;
  font-size: 1.125rem;
}
.logo-main .icon {
  color: #0098db;
  margin-right: 0.625rem;
  font-size: 2rem;
}
.logo-main img {
  flex: 0 0 0.75rem;
  max-width: 0.75rem;
  margin-right: 0.625rem;
}
.fancybox-slide--html {
  padding: 0;
}
.modal-win {
  padding: 0 20px;
  width: 100%;
  position: relative;
}
.modal-win__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.modal-win__inner .logo-main {
  margin-bottom: 1.875rem;
  margin-left: auto;
  margin-right: auto;
}
.modal-win .btn-warning,
.modal-win .sUsers__btn {
  margin-bottom: 1.25rem;
  padding: 0.8125rem 0.625rem;
}
.modal-win .mfp-close {
  color: #0098db;
  font-size: 40px;
  font-weight: 100;
}
a {
  outline: 0 !important;
}
.modal-form {
  position: relative;
}
#foot-dialog h3 {
  font-size: 2.25em;
}
.mfp-close {
  user-select: none;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  opacity: 0.65;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
  overflow: visible;
  cursor: pointer;
  background: 0 0;
  border: 0;
  display: block;
  outline: 0;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  color: #000;
  opacity: 1;
  font-size: 50px;
  font-weight: 100;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  line-height: 56px;
  text-align: center;
  margin-top: -5px;
  margin-right: -5px;
  transition: all 0.2s;
}
.polite .link {
  color: #0098db;
  cursor: pointer;
  text-decoration: underline;
}
.polite .link :hover {
  display: none;
}
.item-video {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 35px;
  padding-bottom: 10px;
}
.item-video__caption {
  padding-top: 10px;
}
.item-video__video-wrap {
  display: block;
  position: relative;
  margin-bottom: 20px;
  border-radius: 0.3125rem;
  overflow: hidden;
}
.item-video__video-wrap:before {
  content: '';
  position: relative;
  height: 0;
  display: block;
  padding-bottom: 57.76173%;
}
.item-video__video-wrap iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
}
.item-video__label {
  color: #ff9500;
  font-weight: 500;
  margin-bottom: 15px;
}
.item-video__title {
  line-height: 1.26923;
  margin-bottom: 1.25rem;
  font-weight: 500;
}
.item-video p {
  color: #828890;
}
.s-contentLogo {
  text-align: center;
}
.s-contentLogo .section-title {
  padding-bottom: 1.875rem;
}
.s-contentLogo p {
  margin-left: auto;
  margin-right: auto;
  max-width: 54.375rem;
  margin-bottom: 2.5rem;
}
.s-contentLogo__lg {
  line-height: 1.5;
  padding-top: 0.625rem;
  margin-bottom: 2.1875rem;
}
.s-contentLogo__logo-wrap {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  height: 11.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.9375rem;
}
.s-contentLogo__item {
  margin-left: auto;
  margin-right: auto;
  max-width: 22.5rem;
  margin-bottom: 30px;
}
.s-contentLogo__links {
  display: flex;
  align-items: center;
  justify-content: center;
}
.s-contentLogo__link {
  text-decoration: none !important;
  border-bottom: 1px solid;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.s-contentLogo__link:hover {
  border-color: transparent;
}
.s-faq {
  counter-reset: num;
  padding-top: calc(var(--sPT) - 0.5rem);
}
.s-faq__item {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 1.375rem;
  margin-bottom: 2.5rem;
}
.s-faq__title {
  font-weight: 500;
  font-size: 18px;
  max-width: 300px;
  padding-left: 2.8125rem;
  position: relative;
  margin-bottom: 1.375rem;
}
.s-faq__title:before {
  content: counter(num);
  counter-increment: num;
  border-radius: 0.3125rem;
  background-color: #f3f6fa;
  color: #828890;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  position: absolute;
  left: 0;
  width: 1.875rem;
  height: 1.875rem;
  top: 0.125rem;
  font-size: 16px;
}
.s-faq p {
  line-height: 1.5;
  color: #828890;
}
.sAbout p {
  margin-bottom: 1.25rem;
  line-height: 1.75;
}
.sAbout--top img {
  max-width: 30.625rem;
}
.sAbout--top h2 {
  padding-bottom: 1.875rem;
}
.sAbout--footer img,
.sAbout--top p {
  max-width: 30.625rem;
}
.sAbout--footer h2 {
  padding-bottom: 1.25rem;
}
.sAbout--footer p {
  max-width: 29.375rem;
}
.sAbout__wrap {
  max-width: 33.1875rem;
  position: relative;
}
.sAbout__wrap h2 {
  padding-bottom: var(--sTPB);
  text-align: center;
}
.sAbout__wrap--2 {
  max-width: 35.625rem;
}
.sAbout img {
  top: -2.5rem;
}
.sAbout__btn {
  width: 13.75rem;
  margin-bottom: 0.625rem;
}
.sAbout__btn.btn-warning,
.sAbout__btn.sUsers__btn {
  padding: 0.8125rem 0.3125rem;
  width: 15rem;
}
.sAbout__btn .icon {
  margin-left: 0.8125rem;
  font-size: 0.75rem;
}
.sAbout__btn .icon-telegram {
  font-size: 2.5rem;
  margin-left: 0;
  margin-right: 0.25rem;
}
.sAbout__label {
  color: #ff9500;
  font-weight: 500;
  padding-bottom: 0.625rem;
}
.sAbout__wrap--ref:nth-child(2n) img {
  left: auto;
  right: 100%;
  margin-left: inherit;
}
.sAbout__wrap--ref p {
  max-width: 31.25rem;
}
.sAbout img {
  will-change: transform;
}
.sAbout__wrap--ceo-pc {
  max-width: 31.875rem;
}
.sAbout__wrap--phone {
  max-width: 33.75rem;
  margin-left: auto;
  margin-right: 0;
}
.sAbout__wrap--phone img {
  margin-bottom: 0 !important;
}
.sAbout__btn-wrap .sAbout__btn {
  width: 240px;
  margin-bottom: 12px;
}
.sAbout__btn-wrap .sAbout__btn.btn-primary,
.sAbout__btn-wrap .sAbout__btn.sBlog__btn,
.sAbout__btn-wrap .sAbout__btn.top-nav__btn {
  height: 3.75rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.sAdvantages .section-title {
  margin-left: auto;
  margin-right: auto;
  max-width: 62.5rem;
}
.sAdvantages--phone .section-title {
  max-width: 50rem;
}
.sAdvantages__col {
  margin-bottom: 1.25rem;
}
.sAdvantages__item {
  border-radius: 10px;
  background-color: #f3f6fa;
  min-height: 100%;
  height: 100%;
  text-align: center;
  transition: all 0.2s;
  padding: 2.8125rem 1.25rem 0.625rem;
}
.sAdvantages__item:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}
.sAdvantages__item--sky {
  padding: 0 !important;
  box-shadow: none !important;
  min-height: 1px !important;
  margin-bottom: 1.25rem;
  background: none;
  margin-left: auto;
  margin-right: auto;
  max-width: 18.75rem;
}
.sAdvantages__img-wrap {
  margin-left: auto;
  margin-right: auto;
  flex: 0 0 6rem;
  width: 6rem;
  height: 6rem;
  box-shadow: 0 0.3125rem 25px rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5625rem;
}
.sAdvantages__img-wrap img {
  flex: 0 0 auto;
}
.sAdvantages__title {
  font-size: 1.625rem;
  line-height: 1.26923;
  font-weight: 500;
  margin-bottom: 1.125rem;
}
.sAdvantages__text {
  font-size: 0.875rem;
  line-height: 1.71429;
}
.sAdvantages__col:not(.col-xl-3):nth-child(-n + 3) {
  flex: 0 0 100%;
  max-width: 100%;
}
.sAdvantages__btn {
  margin-left: auto;
  margin-right: auto;
  width: 19.0625rem;
  padding: 0.75rem 0.3125rem;
}
.sAdvantages__btn.btn-warning,
.sAdvantages__btn.sUsers__btn {
  max-width: 15rem;
  padding: 0.75rem 0.9375rem;
}
.sAdvantages__btn.btn-primary,
.sAdvantages__btn.sBlog__btn,
.sAdvantages__btn.top-nav__btn {
  max-width: 16.25rem;
  padding-right: 1.25rem;
  height: 3.75rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  display: flex;
  align-items: center;
}
.sAdvantages__btn.btn-primary .icon,
.sAdvantages__btn.sBlog__btn .icon,
.sAdvantages__btn.top-nav__btn .icon {
  font-size: 2.5rem;
  margin-right: 0.1875rem;
}
.sAdvantages__btn .icon {
  position: relative;
  top: -0.1875rem;
  font-size: 1.875rem;
  margin-right: 0.9375rem;
}
.sBlog {
  background: #f3f6fa;
}
.sBlog__img-wrap {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: none;
}
.sBlog__item {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: all 0.2s;
  background-color: #fff;
  margin-bottom: 20px;
  display: flex;
  text-decoration: none !important;
}
.sBlog__item:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}
.sBlog__label {
  color: #ff9500;
  margin-bottom: 0.5rem;
}
.sBlog__title {
  font-weight: 500;
  margin-bottom: 0.375rem;
}
.sBlog__title a {
  text-decoration: none !important;
}
.sBlog .icon-eye {
  font-size: 1.125rem;
  margin-right: 0.625rem;
}
.sBlog__caption {
  padding: 1.0625rem 1.25rem;
}
.sBlog__col--lg .sBlog__item {
  display: block !important;
}
.sBlog__col--lg .sBlog__img-wrap {
  display: block;
  position: relative;
  margin-right: 0;
  max-width: 100%;
}
.sBlog__col--lg .sBlog__img-wrap:before {
  content: '';
  position: relative;
  height: 0;
  display: block;
  padding-bottom: 55.71429%;
}
.sBlog__col--lg .sBlog__title {
  font-size: 18px;
  line-height: 1.26923;
  margin-bottom: 1.25rem;
}
.sBlog__btn .icon {
  margin-left: 0.9375rem;
  font-size: 0.75rem;
}
.sForm {
  position: relative;
  z-index: 2;
  background-color: #192d46;
}
.sForm .section-title h2 {
  margin-bottom: 1.25rem;
}
.sForm .section-title p {
  max-width: 30.625rem;
  font-weight: 400;
  line-height: 1.4;
}
.sForm__left-col {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sForm .form-wrap {
  background-color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  padding: 1.5rem 20px 4.0625rem;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.sForm .form-wrap__title-top {
  margin-left: auto;
  margin-right: auto;
  max-width: 26.25rem;
  font-weight: 500;
}
.sForm .form-wrap .btn,
.sForm .form-wrap .btn-scroll-top,
.sForm .form-wrap .headerBlock__btn,
.sForm .form-wrap .sAbout__btn,
.sForm .form-wrap .sAdvantages__btn,
.sForm .form-wrap .sBlog__btn,
.sForm .form-wrap .sPeople__btn,
.sForm .form-wrap .sPromo__btn,
.sForm .form-wrap .sUsers__btn,
.sForm .form-wrap .sWorkingPrinciple__btn,
.sForm .form-wrap .top-nav__btn {
  padding: 1rem;
}
.sForm .custom-select-wrap {
  margin-bottom: 1rem;
}
.sForm .chosen-container .chosen-results li,
.sForm .custom-select-wrap .chosen-single {
  padding: 0.9375rem;
}
.sForm .disabled-result {
  display: none !important;
}
.sPeople {
  background-image: url(../img/svg/map.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}
.sPeople h2 {
  max-width: 31.25rem;
  margin-bottom: 1.25rem;
}
.sPeople__col {
  margin-bottom: 15px;
}
.sPeople__item {
  width: 280px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100%;
  max-height: 348px;
  background: #fff;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  text-decoration: none !important;
}
.sPeople__item img {
  flex: 0 0 auto;
  margin-bottom: 0.875rem;
}
.sPeople__btn {
  margin-top: auto;
}
.sPeople__title {
  color: #000;
  line-height: 1.26923;
  margin-bottom: 0.3125rem;
}
.sPeople__btn {
  padding: 0.9375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3f6fa;
  border: 1px solid #f3f6fa;
  border-radius: 5px;
  font-size: 1rem;
}
.sPeople__btn:hover {
  border-color: #ecf1f7;
  background-color: #ecf1f7;
}
.sPeople__link {
  padding-bottom: 1.25rem;
}
.sPeople__icon-wrap {
  border-radius: 1.875rem;
  width: 1.875rem;
  height: 1.875rem;
  background-color: #0098db;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.625rem;
}
.sPeople__icon-wrap .icon {
  color: #fff;
  font-size: 1.875rem;
}
.sPeople p {
  max-width: 33.75rem;
  margin-bottom: 2rem;
}
.sPromo {
  padding-top: calc(var(--sPT) - 0.625rem);
}
.sPromo h2 {
  padding-bottom: 1.25rem;
}
.sPromo .section-title {
  padding-bottom: calc(var(--sTPB) - 1.25rem);
}
.sPromo .form-wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 33rem;
  margin-bottom: 1.875rem;
}
.sPromo .form-wrap .btn,
.sPromo .form-wrap .btn-scroll-top,
.sPromo .form-wrap .headerBlock__btn,
.sPromo .form-wrap .sAbout__btn,
.sPromo .form-wrap .sAdvantages__btn,
.sPromo .form-wrap .sBlog__btn,
.sPromo .form-wrap .sPeople__btn,
.sPromo .form-wrap .sPromo__btn,
.sPromo .form-wrap .sUsers__btn,
.sPromo .form-wrap .sWorkingPrinciple__btn,
.sPromo .form-wrap .top-nav__btn {
  padding: 0.875rem;
}
.sPromo .form-wrap .row {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.sPromo .form-wrap .row > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.sPromo .custom-select-wrap {
  margin-bottom: 1rem;
}
.sPromo .custom-select-wrap .chosen-single {
  background: #f3f6fa;
}
.sPromo .chosen-container .chosen-results li,
.sPromo .custom-select-wrap .chosen-single {
  padding: 1.0625rem 0.9375rem;
}
.sPromo .disabled-result {
  display: none !important;
}
.sPromo picture {
  margin-bottom: 1.875rem;
}
.sPromo__btn {
  margin-left: auto;
  margin-right: auto;
  padding: 0.8125rem;
}
.sUsers {
  background-color: #192d46;
  color: #fff;
  position: relative;
  z-index: 2;
}
.sUsers .section-title__text {
  font-weight: 400;
  line-height: 1.4;
  padding-top: 0.625rem;
}
.sUsers .row {
  margin-bottom: 0.625rem;
  flex-wrap: nowrap;
  margin-left: 0;
  margin-right: 0;
  border-radius: 0.3125rem;
  background-color: hsla(0, 0%, 100%, 0.15);
  transition: all 0.2s;
  align-items: center;
}
.sUsers .row.header {
  background-color: transparent;
  font-weight: 700;
  margin-bottom: 0.3125rem;
}
.sUsers .column {
  padding: 0.25rem 1.25rem;
  flex: 0 0;
}
.sUsers .column:first-child {
  flex: 0 0 100%;
  width: 100%;
}
.sUsers .column:nth-child(2) {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.sUsers .column:nth-child(2) img {
  flex: 0 0 1.875rem;
  margin-right: 0.75rem;
}
.sUsers .column:nth-child(3) {
  flex-grow: 1;
}
.sUsers .column:nth-child(3) span {
  line-height: 1;
  padding-left: 1rem;
  position: relative;
  display: block;
}
.sUsers .column:nth-child(3) span:before {
  content: '';
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 60px;
  background-color: currentColor;
  position: absolute;
  left: 0;
  top: 0.375rem;
}
.sUsers .column:nth-child(4) {
  flex: 0 0 100%;
  width: 100%;
}
.sUsers__btn {
  margin-left: auto;
  margin-right: auto;
  max-width: 222px;
  padding: 0.8125rem;
  margin-top: 1.5625rem;
}
.sWork {
  text-align: center;
  background: #f3f6fa;
  padding-bottom: calc(var(--sPB) - 40px);
}
.sWork__text {
  max-width: 54.375rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3.4375rem;
}
.sWork .row {
  justify-content: center;
}
.sWork__img-wrap {
  margin-bottom: 1.5625rem;
}
.sWork__img-wrap img {
  max-width: 100%;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: auto;
  max-height: 444px;
}
.sWork__label {
  background: #fff;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 11.625rem;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  color: #000 !important;
  border: 2px solid #fff;
}
.sWork__label:hover {
  border-color: #0098db;
}
.sWork__label img {
  flex: 0 0 auto;
  margin-right: 0.875rem;
}
.sWork__col {
  margin-bottom: 2.5rem;
}
.sWork__caption {
  margin-bottom: 1.875rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 33.75rem;
}
.sWork__caption h2 {
  line-height: 1.26087;
}
.sWorkingPrinciple {
  --swiper-navigation-size: 1.875rem;
  text-align: center;
}
.sWorkingPrinciple .swiper-button-hand {
  color: #0098db;
  height: 30px;
  top: 47.5%;
}
.sWorkingPrinciple .section-title {
  padding-bottom: calc(var(--sTPB) - 2.1875rem);
}
.sWorkingPrinciple .swiper-pagination {
  bottom: 0;
  position: relative;
  margin-bottom: 2rem;
  text-align: center;
}
.sWorkingPrinciple .col,
.sWorkingPrinciple .swiper-container {
  text-align: center;
}
.sWorkingPrinciple .swiper-pagination-bullet {
  background-color: #eaedf1;
}
.sWorkingPrinciple .swiper-pagination-bullet.swiper-pagination-bullet-active,
.sWorkingPrinciple .swiper-pagination-bullet:hover {
  background-color: #0098db;
}
.sWorkingPrinciple .section-title {
  margin-left: auto;
  margin-right: auto;
  max-width: 46.875rem;
}
.sWorkingPrinciple .section-title h2 {
  margin-bottom: 1.875rem;
}
.sWorkingPrinciple .section-title p {
  line-height: 1.75;
}
.sWorkingPrinciple__slider-wrapper {
  position: relative;
}
.sWorkingPrinciple__slide {
  padding: 1.875rem 0.9375rem 2.5rem;
}
.sWorkingPrinciple__slide p {
  line-height: 1.75;
  max-width: 25rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}
.sWorkingPrinciple img {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}
.sWorkingPrinciple .row {
  align-items: center;
}
.sWorkingPrinciple__step {
  color: #ff9500;
  margin-bottom: 1.25rem;
}
.sWorkingPrinciple__title {
  font-weight: 500;
  max-width: 28.75rem;
  line-height: 1.26923;
  margin-bottom: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
.sWorkingPrinciple__btn {
  margin-left: auto;
  margin-right: auto;
  width: 16.25rem;
}
.page-main .top-nav:not(.fixed) .toggle-menu-mobile:not(.on) span,
.page-main .top-nav:not(.fixed) .toggle-menu-mobile span:after,
.page-main .top-nav:not(.fixed) .toggle-menu-mobile span:before {
  background-color: #fff;
}
.toggle-menu-mobile {
  display: block;
  height: 1.5em;
  position: relative;
  font-size: 20px;
}
.toggle-menu-mobile span:after,
.toggle-menu-mobile span:before {
  content: '';
  position: absolute;
  left: 0;
}
.toggle-menu-mobile span {
  position: absolute;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}
.toggle-menu-mobile span:before {
  top: 0.375em;
}
.toggle-menu-mobile span:after {
  bottom: 0.375em;
}
.toggle-menu-mobile span {
  position: relative;
  display: block;
}
.toggle-menu-mobile span,
.toggle-menu-mobile span:after,
.toggle-menu-mobile span:before {
  width: 1em;
  height: 2px;
  background-color: #000;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 6px;
}
.toggle-menu-mobile.on span {
  background-color: transparent;
}
.toggle-menu-mobile.on span:after,
.toggle-menu-mobile.on span:before {
  top: 0;
  bottom: 0;
  transform-origin: 50% 50%;
}
.toggle-menu-mobile.on span:before {
  transform: rotate(45deg);
}
.toggle-menu-mobile.on span:after {
  transform: rotate(-45deg);
}
.page-main .top-nav:not(.fixed) .menu-mobile__inner {
  background-color: #192d46;
  color: #fff;
}
.page-main .top-nav:not(.fixed) .menu-mobile__inner .canvas-wrap {
  display: block;
  opacity: 1;
}
.menu-mobile {
  position: fixed !important;
  z-index: 800;
  height: 101vh;
  top: 0;
  right: 0;
  transform: translateY(-120%);
  transition: all 0.6s;
  will-change: transform;
  width: 100%;
  text-align: center;
}
.menu-mobile .canvas-wrap {
  display: none;
  opacity: 0;
}
.menu-mobile__inner {
  background-color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding: 6.25rem 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: -1;
  font-size: 16px;
  justify-content: center;
  align-items: center;
}
.menu-mobile.active {
  transform: translate(0);
}
.menu-mobile.active .toggle-menu-mobile {
  opacity: 1;
}
.menu-mobile .toggle-menu-mobile {
  cursor: pointer;
  margin-left: -15%;
  position: absolute;
  opacity: 0;
  left: 0;
  right: auto;
  color: #fff;
  top: 10px;
  z-index: 9999;
  background-color: transparent;
}
.menu-mobile .toggle-menu-mobile span,
.menu-mobile .toggle-menu-mobile span:after,
.menu-mobile .toggle-menu-mobile span:before {
  background-color: #fff;
}
.menu-mobile .toggle-menu-mobile.on {
  opacity: 1;
}
.menu-mobile .toggle-menu-mobile.on span {
  background-color: transparent;
}
.menu-mobile ul {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.menu-mobile li {
  display: block;
  margin: 0 0 0.625rem;
}
.menu-mobile__link {
  margin-bottom: 10px;
  border-bottom: 1px solid transparent;
  text-decoration: none !important;
}
.menu-mobile__link.active,
.menu-mobile__link:hover {
  color: #0098db;
  border-color: #0098db;
}
.menu-mobile__link--current {
  color: #0098db !important;
  border-bottom: 1px solid;
}
.menu-mobile__link--current:hover {
  border-color: transparent;
}
.menu-mobile__link--active {
  color: #ff9500 !important;
  border-bottom: 1px solid;
}
.menu-mobile__link--active:hover {
  color: #ff9500 !important;
  border-color: transparent;
}
.menu-mobile__footer {
  padding-bottom: 5vh;
}
.menu-mobile__footer-item {
  margin-left: auto;
  margin-right: auto;
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
}
.menu-mobile__footer-item img {
  flex: 0 0 auto;
  margin-right: 0.625rem;
}
.top-nav {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  transition: all 0.2s;
  width: 100%;
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
}
.top-nav .container {
  z-index: 900;
  position: relative;
}
.top-nav:not(.fixed) a {
  color: #fff;
}
.top-nav ul a {
  color: #000;
}
.top-nav .top-nav__link--current {
  color: #0098db !important;
  border-bottom: 1px solid;
}
.top-nav .top-nav__link--current:hover {
  border-color: transparent;
}
.top-nav .top-nav__link--active {
  color: #ff9500 !important;
  border-bottom: 1px solid;
}
.top-nav .top-nav__link--active:hover {
  color: #ff9500 !important;
  border-color: transparent;
}
.top-nav.fixed {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}
.top-nav .row,
.top-nav__logo {
  align-items: center;
}
.top-nav__logo {
  display: flex;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
  text-decoration: none !important;
  max-width: 200px;
  font-size: 1.125rem;
}
.top-nav__logo .icon {
  color: #0098db;
  margin-right: 0.625rem;
  font-size: 2rem;
}
.top-nav__logo img {
  flex: 0 0 0.75rem;
  max-width: 0.75rem;
  margin-right: 0.625rem;
}
.top-nav__nav {
  text-align: center;
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  margin-bottom: 0;
}
.top-nav__nav > * {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.top-nav__item {
  list-style-type: none;
}
.top-nav__link {
  text-decoration: none !important;
  border-bottom: 1px solid transparent;
  line-height: 1;
}
.top-nav__link.active,
.top-nav__link:hover {
  color: #0098db !important;
  border-color: currentColor;
}
.top-nav__btn {
  color: #fff !important;
  width: 5.3125rem;
}
.top-nav:not(.fixed) select {
  border-color: #3c4c62;
}
.top-nav select {
  color: #000;
  background: none;
  border-color: #e5e5e5;
  text-align: center !important;
  width: 2.75rem;
  text-indent: 12%;
  margin-left: auto;
  text-align: -webkit-center;
  -webkit-appearance: none;
}
.custom-select-wrap--lang select::-ms-expand {
  display: none;
}
.custom-select-wrap {
  --imgW: 1.875rem;
}
.custom-select-wrap .chosen-container {
  max-width: 100%;
}
.custom-select-wrap .chosen-container .chosen-drop {
  border-color: #e5e5e5;
}
.custom-select-wrap .chosen-container-active.chosen-with-drop .chosen-single {
  background: #fff !important;
  border-color: #e5e5e5;
}
.custom-select-wrap .chosen-container-active.chosen-with-drop .chosen-single b {
  transform: rotateX(180deg);
  position: relative;
}
.custom-select-wrap .chosen-container-single .chosen-single div {
  width: 2.8125rem;
}
.custom-select-wrap .chosen-container {
  min-width: 100%;
}
.custom-select-wrap .chosen-container-single .chosen-single div b {
  transition: all 0.2s;
  background-image: url(../img/svg/chevron-down.svg);
  background-position: 50%;
}
.custom-select-wrap .chosen-single {
  padding: 0 0 0 0.6875rem;
  height: inherit;
  display: block;
  box-shadow: none;
  background: #f3f6fa;
}
.custom-select-wrap .chosen-single span {
  display: block;
  margin: 0;
}
.custom-select-wrap .chosen-single .chosen-drop {
  clip-path: none !important;
}
.custom-select-wrap .chose-image-list,
.custom-select-wrap .chose-image-small {
  width: var(--imgW);
  height: var(--imgW);
  max-height: none;
  margin: 0;
}
.custom-select-wrap .option-img-wrap {
  flex: 0 0 var(--imgW);
  width: var(--imgW);
  margin-right: 0.5rem !important;
}
.custom-select-wrap .chosen-container-single.chosen-with-drop a {
  color: #000 !important;
}
.custom-select-wrap .chosen-container-single .option-text {
  margin-right: 0;
  font-size: 1rem;
}
.custom-select-wrap .chosen-container-single .chosen-search {
  display: none !important;
}
.custom-select-wrap .option-row {
  height: calc(1.5em + 2.124rem + 2px);
  margin: 0;
  display: flex !important;
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
}
.custom-select-wrap .chosen-results {
  padding: 0;
}
.custom-select-wrap .chosen-results li {
  transition: all 0.2s;
  padding-left: 0.6875rem;
  border-top: 1px solid #e5e5e5;
}
.custom-select-wrap .chosen-results li.highlighted {
  background: #f3f6fa;
  color: #000;
}
.custom-select-wrap .chosen-results li.result-selected {
  display: none !important;
}
.custom-select-wrap--currency .option-row,
.custom-select-wrap--lang .option-row {
  height: calc(1.5em + 1.15rem + 2px);
}
.custom-select-wrap--currency .chose-image-list,
.custom-select-wrap--currency .chose-image-small,
.custom-select-wrap--lang .chose-image-list,
.custom-select-wrap--lang .chose-image-small {
  width: 1.625rem;
  height: 1.625rem;
}
.custom-select-wrap--currency .option-img-wrap,
.custom-select-wrap--lang .option-img-wrap {
  flex: 0 0 1.625rem;
  width: 1.625rem;
}
.custom-select-wrap--lang {
  width: 4.625rem;
}
.custom-select-wrap--lang b {
  display: none !important;
}
.custom-select-wrap--lang .chosen-single {
  background: none;
  border-color: transparent;
}
.custom-select-wrap--currency {
  width: 11.9375rem;
}
.custom-select-wrap--currency2 {
  width: 12.9375rem;
}
.custom-select-wrap--currency .chosen-container-single .chosen-single div b {
  transform: none;
  background-image: url(../img/svg/dots.svg);
  background-position: 0;
}
.chosen-container .chosen-results {
  margin: 0;
  max-height: 16.675rem;
}
.top-nav .chosen-container-single .chosen-single div {
  width: 1.25rem;
}
.page-main .top-nav:not(.fixed) select:not(:focus) {
  color: #fff;
}
.page-main .top-nav:not(.fixed) .custom-select-wrap a {
  color: #fff !important;
}
.page-main .top-nav:not(.fixed) .custom-select-wrap .chosen-with-drop a {
  border-color: #fff;
  color: #000 !important;
}
.page-main .top-nav:not(.fixed) .custom-select-wrap--currency .chosen-single {
  color: #fff;
  background: none;
  border: 1px solid #3c4c62;
}
.chosen-container .chosen-results {
  color: #000;
}
@media (min-width: 347px) {
  body,
  html {
    font-size: 14px !important;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group,
  .form-inline .form-wrap .custom-select-wrap,
  .form-wrap .form-inline .custom-select-wrap {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .custom-select-wrap .form-inline .chosen-single,
  .form-inline .custom-select-wrap .chosen-single,
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .footer__logo .icon {
    font-size: 2.8125rem;
  }
  .footer__logo {
    font-size: 1.625rem;
  }
  .footer__logo img {
    flex: 0 0 1.0625rem;
    max-width: 1.0625rem;
  }
  .headerBlock {
    padding-top: 7.5rem;
  }
  .headerBlock h1 {
    margin-bottom: 2.8125rem;
  }
  .headerBlock__content p {
    margin-bottom: 2.5rem;
  }
  .headerBlock--minh1 h1 {
    margin-bottom: 1.875rem;
    padding-top: 0.3125rem;
  }
  .logo-main {
    font-size: 1.625rem;
  }
  .logo-main .icon {
    font-size: 2.8125rem;
  }
  .logo-main img {
    flex: 0 0 1.0625rem;
    max-width: 1.0625rem;
  }
  .modal-text {
    max-width: 1000px;
  }
  .sAbout__btn-wrap .sAbout__btn {
    width: 100%;
  }
  .sBlog__img-wrap {
    display: block;
    position: relative;
    max-width: 9.125rem;
  }
  .sBlog__img-wrap:before {
    content: '';
    position: relative;
    height: 0;
    display: block;
    padding-bottom: 100%;
  }
  .sBlog__caption {
    padding: 1.0625rem 1.875rem;
  }
  .sBlog__col--lg .sBlog__title {
    font-size: 1.625rem;
  }
  .sPromo picture {
    margin-bottom: 2.8125rem;
  }
  .sPromo__btn {
    max-width: 16.25rem;
  }
  .sWorkingPrinciple__slide {
    padding: 1.875rem 1.875rem 2.5rem;
  }
  .top-nav__logo {
    font-size: 1.625rem;
  }
  .top-nav__logo .icon {
    font-size: 2.8125rem;
  }
  .top-nav__logo img {
    flex: 0 0 1.0625rem;
    max-width: 1.0625rem;
  }
}
@media (min-width: 768px) {
  :root {
    --sPT: 5rem;
    --sPB: 5.625rem;
    --sTPB: 2.5rem;
  }
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .footer__col {
    width: 13.75rem;
    text-align: left;
  }
  .footer__logo {
    justify-content: start;
  }
  .footer__logo,
  .footer__text {
    margin-bottom: 0;
  }
  .headerBlock__inner {
    color: #000;
    background: #fff;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1.4375rem 1.5625rem;
  }
  .headerBlock .swiper-wrapper {
    justify-content: space-between;
  }
  .headerBlock__content picture img {
    max-width: 31.25rem;
  }
  .headerBlock__link-btn-wrap {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5625rem;
    margin-right: -0.5625rem;
  }
  .headerBlock__link-btn {
    margin-left: 0.5625rem;
    margin-right: 0.5625rem;
  }
  .headerBlock__contact-wrap {
    background: #fff;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1.5rem 2.75rem;
  }
  .s-faq__item {
    padding-left: 4.6875rem;
    margin-bottom: 3.375rem;
  }
  .s-faq__title {
    position: static;
    padding-left: 0;
    max-width: none;
    font-size: 1.625rem;
  }
  .s-faq__title:before {
    font-size: 1.25rem;
    top: -0.375rem;
    width: 2.8125rem;
    height: 2.8125rem;
  }
  .sAbout__wrap h2 {
    text-align: left;
    max-width: 25rem;
  }
  .sAbout__wrap--2 h2 {
    max-width: 35.625rem;
  }
  .sAbout img {
    position: absolute;
    left: 85%;
    max-height: 43.125rem;
  }
  .sAbout__btn {
    margin-top: 2.1875rem;
    margin-bottom: 2.125rem;
  }
  .sAbout__wrap--ref:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
    margin-top: 8.4375rem;
    max-width: 31.25rem;
  }
  .sAbout__wrap--ref:nth-child(2n) img {
    margin-top: 3.125rem;
    margin-right: 3.125rem;
    max-height: 28.4375rem;
  }
  .sAbout__wrap--ceo-pc img {
    left: 100%;
    top: 1rem;
    max-height: 100%;
    max-width: 800px;
  }
  .sAbout__wrap--ceo-pc h2,
  .sAbout__wrap--ceo-pc p {
    max-width: 100%;
  }
  .sAbout__wrap--phone {
    margin-top: 1.875rem;
    margin-bottom: 9.375rem;
  }
  .sAbout__wrap--phone img {
    right: 100% !important;
    left: auto !important;
    margin-left: auto !important;
    margin-right: 6.25rem !important;
  }
  .sAdvantages__col:not(.col-xl-3):nth-child(-n + 3) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .sAdvantages__col:not(.col-xl-3):nth-child(n + 4) .sAdvantages__img-wrap {
    margin-bottom: 0;
    margin-left: 1.25rem;
  }
  .sAdvantages__col:not(.col-xl-3):nth-child(n + 4) .sAdvantages__text {
    padding-bottom: 0;
    max-width: 20.625rem;
  }
  .sAdvantages__col:not(.col-xl-3):nth-child(n + 4) .sAdvantages__item {
    display: flex;
    text-align: left;
    flex-direction: row-reverse;
  }
  .sAdvantages__btn {
    margin-top: 1.5625rem;
  }
  .sPeople h2 {
    margin-bottom: 2.1875rem;
  }
  .sPeople__item {
    width: 16.375rem;
  }
  .sUsers .row:not(.header):hover {
    transform: translateX(6px);
  }
  .sUsers .column {
    padding: 0.875rem 0.625rem;
  }
  .sUsers .column:first-child {
    flex: 0 0 21.05263%;
    width: 21.05263%;
  }
  .sUsers .column:nth-child(2) {
    flex: 0 0 17.10526%;
    width: 17.10526%;
    margin-left: 0;
    flex-grow: 0;
  }
  .sUsers .column:nth-child(4) {
    flex: 0 0 17.10526%;
    width: 17.10526%;
  }
  .sUsers .column:nth-child(5) {
    flex: 0 0 20.17544%;
    width: 20.17544%;
  }
  .sUsers__btn {
    margin-top: 3.125rem;
  }
  .sWork__img-wrap img {
    max-height: 21.25rem;
  }
  .sWork__caption {
    margin-left: 0;
    margin-right: 0;
  }
  .sWorkingPrinciple .col,
  .sWorkingPrinciple .swiper-container {
    text-align: left;
  }
  .sWorkingPrinciple__slide {
    padding: 1.875rem 3.125rem 3.75rem;
  }
  .sWorkingPrinciple__slide p {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .sWorkingPrinciple__step {
    margin-bottom: 2.1875rem;
  }
  .sWorkingPrinciple__title {
    margin-bottom: 1.875rem;
    margin-left: 0;
    margin-right: 0;
  }
  .top-nav {
    padding-top: 2.8125rem;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .wow {
    visibility: hidden;
  }
  .headerBlock {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
  .headerBlock__inner {
    padding: 1.4375rem 2.8125rem;
  }
  .headerBlock__content {
    margin-right: 0;
    text-align: left;
  }
  .headerBlock__content picture {
    text-align: left;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
  .headerBlock__content h1,
  .headerBlock__content p {
    text-align: left;
  }
  .headerBlock__btn-wrap {
    max-width: 31.25rem;
  }
  .headerBlock__btn {
    max-width: 260px;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .headerBlock__label {
    text-align: left;
  }
  .headerBlock--for .headerBlock__content {
    max-width: 34rem;
  }
  .headerBlock--for-phone picture {
    left: 3.125rem !important;
  }
  .headerBlock--for-phone img {
    max-width: none !important;
  }
  .modal-win__inner .form-wrap__title {
    position: absolute;
    right: 100%;
    margin-right: 1.875rem;
    top: 1.125rem;
    white-space: nowrap;
  }
  .modal-win__inner {
    padding-top: 8.125rem;
    padding-bottom: 2.5rem;
  }
  .modal-win__inner .logo-main {
    position: absolute;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    top: 4.0625rem;
  }
  .item-video {
    margin-bottom: 44px;
    padding-bottom: 22px;
  }
  .item-video__label {
    margin-bottom: 1.25rem;
  }
  .sAbout--footer p,
  .sAbout--top p {
    margin-bottom: 0.0625rem;
  }
  .sAbout {
    padding-top: calc(var(--sPT) + 2rem);
  }
  .sAbout--top {
    padding-top: calc(var(--sPT) + 3.25rem);
  }
  .sAbout--png,
  .sAbout--top {
    padding-bottom: calc(var(--sPB) + 0.4375rem);
  }
  .sAbout--png {
    padding-top: calc(var(--sPT));
  }
  .sAbout--footer {
    padding-bottom: calc(var(--sPB) - 0.625rem);
  }
  .sAbout--footer img,
  .sAbout--top img {
    margin-left: 5.3125rem;
  }
  .sAbout__label {
    margin-top: 1.875rem;
    padding-top: 2.5rem;
  }
  .sAbout__wrap--ref img {
    max-height: 34.25rem;
  }
  .sAbout__wrap--ref h2 {
    padding-bottom: 1.875rem;
  }
  .sAbout__wrap--phone {
    margin-top: 4.375rem;
    margin-bottom: 8.75rem;
  }
  .sAdvantages__item {
    padding: 2.8125rem;
    min-height: 22.875rem;
  }
  .sAdvantages__col:not(.col-xl-3):nth-child(n + 4) .sAdvantages__item {
    align-items: center;
    padding: 3.125rem;
    min-height: 14.375rem;
  }
  .sAdvantages__col:nth-child(n + 4) .sAdvantages__title {
    max-width: 18.75rem;
  }
  .sForm {
    padding-bottom: 0 !important;
  }
  .sForm .form-wrap {
    margin-left: auto;
    max-width: 34.625rem;
    margin-right: 0;
    padding: 1.5rem 1.875rem 4.0625rem;
    margin-bottom: 3.75rem;
  }
  .sPeople p {
    margin-bottom: 1rem;
  }
  .sWork .row {
    justify-content: space-around;
  }
  .sWork__img-wrap {
    margin-bottom: 2.8125rem;
  }
  .sWork__caption {
    padding-top: 8%;
  }
  .sWork__caption h2 {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1160px;
  }
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .headerBlock__container {
    max-width: 1150px !important;
  }
  .headerBlock__content picture img {
    max-width: 40.625rem;
  }
  .headerBlock__content picture {
    left: -50px;
    margin-bottom: 0;
    margin-top: -2.5rem;
  }
  .headerBlock__contact-wrap {
    margin-left: 0.625rem;
    margin-bottom: 0;
  }
  .headerBlock--for picture {
    transform: none !important;
    top: 0 !important;
  }
  .headerBlock--for img {
    max-width: none !important;
  }
  .headerBlock--for-phone picture {
    left: 6.25rem !important;
    margin-top: 2vh;
  }
  .item-video__caption {
    padding-left: 34px;
  }
  .sAbout img {
    left: 100%;
    max-width: none;
    margin-left: 2.8125rem;
  }
  .sAbout--top img {
    margin-left: 5.3125rem;
  }
  .sAbout--footer img {
    margin-left: 5rem;
    margin-top: 0.9375rem;
  }
  .sAbout__wrap--ref:nth-child(2n) img {
    margin-right: 6.25rem;
  }
  .sAbout__wrap--ceo-pc img {
    margin-left: 6.25rem;
    max-width: none;
  }
  .sAbout__wrap--phone {
    margin-top: 1.25rem;
    margin-bottom: 7.5rem;
  }
  .sBlog__col--lg .sBlog__item {
    min-height: 100%;
    margin-bottom: 0;
  }
  .sBlog__col--lg .sBlog__caption {
    padding: 2.0625rem 2.875rem;
  }
  .sBlog__col--lg .sBlog__label {
    padding-bottom: 0.9375rem;
  }
  .sForm .form-wrap {
    margin-top: 1.25rem;
    padding: 1.5rem 2.75rem 4.0625rem;
  }
  .sUsers .column {
    padding: 0.875rem 1.875rem 0.9375rem;
  }
  .sWork__img-wrap img {
    max-height: 373px;
  }
  .sWork__row {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }
  .sWork__row > * {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .sWorkingPrinciple__slide {
    padding: 1.875rem 3.125rem 3.75rem;
  }
  .sWorkingPrinciple__content {
    margin-left: 1.875rem;
    padding-bottom: 2.5rem;
  }
  .top-nav .container {
    max-width: 86.25rem;
  }
  .top-nav .row {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .top-nav .row > * {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
@media (min-width: 1400px) {
  .headerBlock {
    padding-top: 9.375rem;
  }
  .headerBlock__container {
    min-width: 1350px !important;
  }
}
@media (min-width: 1440px) {
  body,
  html {
    font-size: 16px !important;
  }
  .headerBlock--for picture {
    left: 0 !important;
  }
  .sAbout__wrap--ceo-pc {
    max-height: none;
  }
  .sAbout__wrap--phone {
    margin-bottom: 5rem;
  }
}
@media (max-width: 1400px) {
  .headerBlock .swiper-wrapper img {
    max-width: 7.5rem;
    max-height: 1.875rem;
  }
}
@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
  .h1,
  h1 {
    font-size: calc(1.45rem + 2.4vw);
  }
  .h2,
  h2 {
    font-size: calc(1.4125rem + 1.95vw);
  }
  .h3,
  h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
  .h4,
  h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
  .h5,
  .headerBlock__group-link,
  .headerBlock__label,
  .item-video__title,
  .s-contentLogo__lg,
  .sPeople__title,
  .sWorkingPrinciple__title,
  h5 {
    font-size: calc(1.2875rem + 0.45vw);
  }
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
}
@media (max-width: 991.98px) {
  .sAbout--png {
    padding-bottom: 0;
  }
  .sForm .section-title {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
  }
  .sForm .section-title p {
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
  }
  .sPeople {
    text-align: center;
  }
  .sPeople h2,
  .sPeople p {
    margin-left: auto;
    margin-right: auto;
  }
  .animated {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}
@media (max-width: 767.98px) {
  .headerBlock__title {
    margin-bottom: 13px;
  }
  .headerBlock__group.d-flex {
    margin-bottom: 3px;
  }
  .headerBlock .form-wrap {
    margin-left: -20px;
    margin-right: -20px;
    padding: 25px 20px;
    background-color: #fff;
    margin-top: 1.25rem;
  }
  .sAbout {
    text-align: center;
  }
  .sAbout__wrap {
    margin-left: auto;
    margin-right: auto;
  }
  .sAbout img {
    margin-bottom: 2rem;
  }
  .sAbout__wrap--ref {
    padding-bottom: 1rem;
  }
  .sAbout__wrap--ref img {
    margin-bottom: 0;
  }
  .sAbout__wrap--ref:nth-child(2n) h2 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .sAbout__wrap--ref:nth-child(2n) img {
    margin-top: 10px;
  }
  .sAdvantages__text,
  .sAdvantages__title {
    margin-left: auto;
    margin-right: auto;
    max-width: 18.75rem;
  }
  .sUsers .row {
    flex-wrap: wrap;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    margin-bottom: 15px;
  }
  .sUsers .row.header {
    display: none;
  }
  .sUsers .column:first-child {
    order: 1;
  }
  .sUsers .column:nth-child(3) {
    flex: 0 0 100%;
    width: 100%;
    order: 2;
  }
  .sUsers .column:nth-child(4) {
    order: 3;
  }
  .custom-select-wrap--lang {
    width: 3.125rem;
    text-align: center;
  }
  .custom-select-wrap--lang .option-img-wrap {
    margin-right: 0 !important;
  }
  .custom-select-wrap--lang .chosen-single {
    border: 1px solid #e5e5e5;
  }
  .custom-select-wrap--lang .option-text {
    display: none !important;
  }
  .page-main .top-nav:not(.fixed) .custom-select-wrap--lang .chosen-single {
    border: 1px solid #3c4c62;
  }
}
@media (max-width: 575.98px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sAbout img {
    max-width: 100%;
  }
  .sForm .form-wrap__title-top {
    font-size: 18px;
  }
  .sWorkingPrinciple__slider-wrapper {
    margin-left: -15px;
    margin-right: -15px;
  }
  .sWorkingPrinciple .swiper-container {
    overflow: inherit;
  }
}
@media (max-width: 1365.98px) and (min-width: 1200px) {
  .sAbout--footer,
  .sAbout--top {
    padding-bottom: 8.75rem;
  }
}
@media (max-width: 1199.98px) and (min-width: 992px) {
  .sAbout--footer,
  .sAbout--top {
    padding-bottom: 5rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .btn,
  .btn-scroll-top,
  .custom-select-wrap .chosen-single,
  .form-control,
  .headerBlock__btn,
  .sAbout__btn,
  .sAdvantages__btn,
  .sBlog__btn,
  .sPeople__btn,
  .sPromo__btn,
  .sUsers__btn,
  .sWorkingPrinciple__btn,
  .top-nav__btn {
    transition: none;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
