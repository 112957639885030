$output-bourbon-deprecation-warnings: false;
// @import "mq";
@import "bourbon/app/assets/stylesheets/bourbon";
// @import "../../node_modules/rfs/scss";

@import "mixin";
@import "fonts";
//@import "./public/img/svg/_sprite";
// @import "bootstrap-theme/bootstrap";

// Option B: Include parts of Bootstrap


@import "vars";
// @import "./node_modules/bootstrap/scss/vendor/rfs";
// @import "./node_modules/bootstrap/scss/functions";
// @import "./node_modules/bootstrap/scss/variables";
// @import "./node_modules/bootstrap/scss/mixins";

// @import "root";

// $theme-colors: map-remove($theme-colors, "info", "light", "dark",'warning');
// @import "./node_modules/bootstrap/scss/root";
// @import "./node_modules/bootstrap/scss/reboot";
// @import "./node_modules/bootstrap/scss/type";
// // @import "./node_modules/bootstrap/scss/images";
// // @import "./node_modules/bootstrap/scss/code";
// @import "./node_modules/bootstrap/scss/grid";
// // @import "./node_modules/bootstrap/scss/tables";
// @import "./node_modules/bootstrap/scss/forms";
// @import "./node_modules/bootstrap/scss/buttons";
// // @import "./node_modules/bootstrap/scss/transitions";
// // @import "./node_modules/bootstrap/scss/dropdown";
// // @import "./node_modules/bootstrap/scss/button-group";
// // @import "./node_modules/bootstrap/scss/input-group";
// // @import "./node_modules/bootstrap/scss/custom-forms";
// // @import "./node_modules/bootstrap/scss/nav";
// // @import "./node_modules/bootstrap/scss/navbar";
// // @import "./node_modules/bootstrap/scss/card";
// @import "./node_modules/bootstrap/scss/breadcrumb";

// .breadcrumb {
// 	.active {
// 		a {
// 			color: $breadcrumb-active-color;
// 			pointer-events: none;
// 		}
// 	}
// }

// @import "./node_modules/bootstrap/scss/pagination";

// .pagination-wrapper ul {
// 	@extend .pagination;
// }

// .pagination-wrapper li .page-numbers {
// 	@extend .page-link;
// }

// .pagination-wrapper li {
// 	@extend .page-item;

// 	.current {
// 		z-index: 1;
// 		color: $pagination-active-color;
// 		background-color: $pagination-active-bg;
// 		border-color: $pagination-active-border-color;
// 	}
// }

// // @import "./node_modules/bootstrap/scss/badge";
// // @import "./node_modules/bootstrap/scss/jumbotron";
// // @import "./node_modules/bootstrap/scss/alert";
// // @import "./node_modules/bootstrap/scss/progress";
// // @import "./node_modules/bootstrap/scss/media";
// // @import "./node_modules/bootstrap/scss/list-group";
// // @import "./node_modules/bootstrap/scss/close";
// // @import "./node_modules/bootstrap/scss/toasts";
// // @import "./node_modules/bootstrap/scss/modal";
// // @import "./node_modules/bootstrap/scss/tooltip";
// // @import "./node_modules/bootstrap/scss/popover";
// // @import "./node_modules/bootstrap/scss/carousel";
// // @import "./node_modules/bootstrap/scss/spinners";
// @import "./node_modules/bootstrap/scss/utilities";


// @import "print";

// @import "../libs/animate";
// @import "fontawesome-pro-5.0.8/web-fonts-with-css/scss/fontawesome";
// @import "fontawesome-pro-5.0.8/web-fonts-with-css/scss/fa-brands";
// @import "fontawesome-pro-5.0.8/web-fonts-with-css/scss/fa-regular";
// @import "fontawesome-pro-5.0.8/web-fonts-with-css/scss/fa-solid";
// @import "fontawesome-pro-5.0.8/web-fonts-with-css/scss/fa-light";
// @import "../libs/magnificPopup/magnific-popup.sass";
// @import "../libs/swiper/swiper.min.css";
// @import "../libs/select2-4.0.6-rc.1/dist/css/select2.min";
// @import "../libs/dropzone";
// @import "../libs/gijgo-master/dist/combined/css/gijgo";
// @import "../libs/gijgo-master/dist/combined/css/demo.min";

// @import "../libs/ion.rangeSlider/css/ion.rangeSlider.min.css";

@import "base";
//@import '../pug/blocks/**/*.scss';
// @import "topLine";
// @import "media"; // Всегда в конце


body{
	position: relative;
	&::after{
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1000;
		pointer-events: none;
		opacity: 0;
		opacity: 0.7;
		// content: url(../screen/2.png);

	}

}
 

