
body,
html {
	font-family: Euclid Circular B,
	-apple-system,
	BlinkMacSystemFont,
	Segoe UI,
	Roboto,
	Helvetica Neue,
	Arial,
	Noto Sans,
	sans-serif,
	Apple Color Emoji,
	Segoe UI Emoji,
	Segoe UI Symbol,
	Noto Color Emoji !important;

}

html.personal-page,
body.personal-page {
		@media (max-width: 1440px) {
			font-size: 14px !important;
		}
	@media (max-width: 1440px) {
		font-size: 13px !important;
	}
}

.MuiTypography-body1,
.MuiTableCell-root{
	font-family: Euclid Circular B,
	-apple-system,
	BlinkMacSystemFont,
	Segoe UI,
	Roboto,
	Helvetica Neue,
	Arial,
	Noto Sans,
	sans-serif,
	Apple Color Emoji,
	Segoe UI Emoji,
	Segoe UI Symbol,
	Noto Color Emoji !important;
}


.mobile-hidden{
	// display: block;
	@media (max-width: 767.98px) {
		display: none !important;
	}
}
.mobile-show{
		@media (min-width: 768px) {
			display: none !important;
		}
}


.TopBar{
	@media (max-width: 767.98px){


		background-color: #fff;
		margin-top: 0 !important;
		margin-left: -15px;
		margin-right: -15px;
		// padding-left: 10px;
		padding-right: 10px;
		.selected{
			padding-right: 0 !important;
		}
		.userBalance{
			order: -1;
			margin-right: auto;
			[viewBox="0 0 452 452"] {
				display: none;
			}
		}
	}

}


.toggleMenu{
	$h: 2px;
	display: none;
	color: #0098db;
	cursor: pointer;
	@media (max-width: 767.98px){
		display: block;
		position: relative;

	}
	width: 48px;
	height: 48px;


	span:after,
	span:before {
		content: "";
		position: absolute;
		left: 0;
	}

	span {
		@include a-cent(y);
	}

	span:before {


		top: $h * 4;
	}

	span:after {
		bottom: $h * 4;
	}

	span {
		position: relative;
		display: block;
		left: 3px;
	}

	span,
	span:after,
	span:before {
		// width: 32px;
		width: 22px;
		height: $h;
		background-color: currentColor;
		transition: all 0.3s;
		backface-visibility: hidden;
		border-radius: 6px;
	}

	// &.on {
		// 	// opacity: 0;
		// }

		&.on span {
			background-color: transparent;
		}

		&.on span:after,
		&.on span:before {

			top: 0;
			bottom: 0;
			transform-origin: 50% 50%;
		}

	&.on span:before {
		transform: rotate(45deg);
	}

	&.on span:after {
		transform: rotate(-45deg);
	}
}

@media (max-width: 767.98px){
	.PageTitle,
	.wrap-tablet{
		margin-top: rem(20) !important;
	}
	.WalletBalance{
		display: block;
		width: 100%;
		order: 2;
		line-height: 1;
		svg{
			position: relative;
			top: rem(-5);
		}
	}
	.WalletName{
		padding-left: 0 !important;
	}
}

.wrap-tablet tbody, .wrap-tablet thead {

	@media (min-width: 768px){

		td,
		th{
			&:nth-child(1),
			&:nth-child(2){
				display: none !important;
			}
		}
	}
	@media (max-width: 1500px) {
			td,
			th {

				padding: 0px 1rem !important;
			}
	}
	@media (max-width: 767.98px){

		.ClipText{
			width: initial !important;
			max-width: none !important;
		}
		tbody,
		table, tr{
			height: auto !important;
			display: block;
			width: 100% ;
		}
		tbody tr{
			display: flex !important;
			flex-wrap: wrap;
			background-color: #f5f5f5;
			border-radius: $border-radius  ;
			margin-bottom: 5px;
			border: 2px solid #e8e8e8;
			cursor: pointer;
		}
		td{
			display: block;
			font-weight: 500;
			&:nth-child(1),
			&:nth-child(2){
				padding: rem(16) rem(12) !important;
				}
				&:nth-child(1){

				}

				&:nth-child(2){
					padding-left: 0 !important;
					border-radius: 0 $border-radius $border-radius 0 ;
					flex-grow: 1;
					display: flex;
					position: relative;
					padding-right: rem(20);
					&::after{
						content: url(../img/chevron-down.svg);
						// position: relative;
						position: absolute;
						margin-left: auto;
						right: 10px;
					}
				}
				&:nth-child(3) {
					border-top: 2px solid #e8e8e8 !important;
					&::before{
						content: 'Дата: ';
					}
				}
				&:nth-child(4) {
					&::before{
						content: 'Сумма: ';
					}
				}
				&:nth-child(5) {
					&::before{
						content: 'Действие: ';
					}
				}
				&:nth-child(6) {
					&::before{
						content: 'ID операции: ';
					}
				}
				&:nth-child(n + 3) {
					display: none;
					padding: rem(10) rem(12) !important;
					width: 100%;
					flex-wrap: wrap;
					&::before{
						margin-right: 10px;
						font-weight: bold;
					}
				}
			}
			tr.active td{

				&:nth-child(2){
					&::after{
						transform: rotateX(180deg);
					}
				}
				&:nth-child(n + 3){

					display: flex !important;

				}
			}
			.MuiTableRow-head{
				display: none !important;
			}
	tfoot {
			display: block;
			width: 100%;

			td,
			tr {
				display: block;
				width: 100%;
				padding: 0 !important;
			}
		}
		}
	}

	.mobileProfile{

		.mobile-hidden{
			display: flex !important;
		}
	}

	.MobileMenu {
		height: 100vh;
		overflow-y: auto;
		overflow-x: hidden;
		@media (min-width: 768px){
			display: none !important;
		}

		@include mt;
			transform: translateY(-100%);
			&.on{
				transform: translateY(0);
			}
			top: 0;
			position: fixed;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #ffffff;
			z-index: 200;
			&__head{
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;
				justify-content: space-between;
				padding-right: 15px;
			> * {
				padding: 10px 25px !important;
				// padding-right: 20px;
			}
		}
		&__body{
			padding-left: 5px;
			padding-right: 10px;
		}
		.popover{
			// position: static;
			width: 100%;
		}
	}


.FilterBox{
	margin-top: 0 !important;
	margin-bottom: rem(16);
	min-height: rem(90);
	font-size: rem(18);
	padding-left: rem(30) !important;
	padding-right: rem(30) !important;
	flex-wrap: nowrap;
	// align-items: center;
	* {
		font-size: rem(18);
	}
		.MuiSvgIcon-root {
			font-size: rem(30);
			margin-bottom: 0;
		}
		.PrivateSwitchBase-root-32{
			padding: rem(5);
			margin-right: rem(10);
		}
		.MuiFormControlLabel-root{
			margin-bottom: 0;
		}
		&__btn{
			// width: auto;
			@media (min-width: 1281px){

			}
			width: rem(216);
		}
	}
	.radio-wrap{
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@media (max-width: 767.98px){

			width: 100%;
		}
			}
			.radio-wrap-group{
				align-items: center;
				display: flex;
			}
.MuiTable-root{
	display: table;
	@media (max-width: 1240.69px){
		td {
		margin-bottom: 10px;
		width: 100%;
		}
	}
	@media (max-width:615px) {
		th{
			visibility: hidden;
			padding-left: 0.625rem !important;
    		min-width: 1px !important;
    		white-space: nowrap;
		}
		td{
			display: block;
			min-width: 1px !important;
			margin-bottom: 10px;
			white-space: nowrap;
			border-bottom: 5px solid black;
		}
	}
}
.page_skypay__tableRow {
	@media (max-width:980px) {
		th{
			display: none;
			width: 0;
			padding-left: 0.625rem !important;
    	// min-width: 1px !important;
    	white-space: nowrap;
		}
		td{
			display: block;
			min-width: 1px !important;
			margin-bottom: 10px;
			white-space: nowrap;
			border-bottom: 5px, solid, black;
		}
	}
}
.page_skypay__tableHead {
	@media (max-width:980px) {
		th{
			display: none;
			width: 0;
			padding-left: 0.625rem !important;
    	// min-width: 1px !important;
    	white-space: nowrap;
		}
		td{
			display: block;
			min-width: 1px !important;
			margin-bottom: 10px;
			white-space: nowrap;
			border-bottom: 5px, solid, black;
		}
	}
}
OperationgFilter{
	width:100%;
}
.deals-cards{
	display: grid !important;
	.select-icon-wrap{
		// position: relative;
		position: static !important;
		display: inline-block;
		transform: translateY(0%) !important;
		margin-left: -1.8rem !important;
		margin-right: -1rem !important;
	}
	@media (min-width: 768px){
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: rem(30px);
		grid-row-gap: rem(30px);
	}
	grid-column-gap: rem(25);
	grid-row-gap: rem(25);
	@media (min-width: 1281px){
		grid-template-columns: repeat(3, 1fr);
	}


	> .item{
		margin: 0;
		width: 100%;
	}
}

*{
	box-sizing: border-box;
}

	@media (max-width: 767.98px) {
		.FilterBox{
			background-color: transparent !important;
			flex-wrap: wrap;
			padding: 0 !important;
			max-width: 100%;
			min-width: 1px !important;
			margin-top: 0;
		}
		.radio-wrap{
			max-width: 100%;
			background-color: #ffffff !important;
			order: 1;
			padding: rem(25) rem(20) rem(10);
		}
		.radio-wrap__title{
			display: block;
			max-width: 100%;
			width: 100%;
		}
		.radio-wrap-group{
			max-width: 100%;
			// fxw
			display: block;
			width: 100%;
			margin-bottom: 1rem;
			> *{
				margin-left: 0 !important;
			}
		}
		.FilterBox__btn{

			// margin-bottom: rem(10);
			width: auto !important;
			height: auto !important;
			margin-bottom: rem(25);
			span{
				color: $body-color !important;
				display: flex !important;
				align-items: center !important;
				&::before{
					content: '+';
					width: 22px;
					height: 22px;
					border-radius: 50%;
					color: #ffffff;
					background-color: $blue;text-align: center;
					margin-right: 9px;
					font-size: 22px;
					line-height: .96;
				}

				text-transform: none;
			}
			background: none !important;
			padding: 0 !important;
			border: none  !important;
		}
		.MuiFormControlLabel-root{
			margin-left: 0 !important;
		}
	}

	body.fixedd{
		overflow: hidden;
	}
	.filter-p2p {

		margin-bottom: rem(30) !important;
		.popover{
			@media (max-width: 767.98px){
				max-height: calc(100vh - 36rem);

			}
			max-height: calc(100vh - 22rem);
		}
		// display: none !important;
			@media (max-width: 767.98px){

				> * {
					margin-left: 0 !important;
					margin-right: 0 !important;
					width: 100%;
					padding: 0;
					padding-bottom: 1rem;
				}
			}
	}

	.usersTable{
		margin-bottom: 30px;
	}
	.usersTable,
	.block-p2p{
		@media (min-width: 1100px){
			margin-top: 30px;

		}
		margin-top: 1px;
		@media (max-width: 1279.98px){
			.select-icon-wrap{
				text-align: left;
				img{

				}
			}
			thead{
				// display: none;
				th{
					span{

						display: none;
					}
					&:first-child{
						display: table-cell;
						// column-span: 3;
						&::before{
							content: 'Предложения продавцов';
							font-size: 14px;
							font-weight: 700;
							width: 100%;
							border-radius: $border-radius;
							background-color: #dadddf;
							// display: table-cell;
							color: #2a2a31;
							display: block;
							white-space: nowrap;
							margin-right: -200px;
						}
					}
				}
			}
			table{

			}
		}
		td{
			@media (max-width: 1279.98px){
				padding: 0px .6rem !important;
			}
		}
			@media (max-width: 767.98px) {
				table{
					margin-top: -1.875rem;
					margin-left: -1.875rem;
					margin-right: -1.875rem;
					width: initial;
				}
				thead{
					display: none;
				}
				td,
				tbody,
				table{
					display: block;
				}
				tr{
					display: flex;
					flex-wrap: wrap;
					border-bottom: 1px solid #e8e8e8;
					padding: rem(10) rem(10) .5rem;
					height: auto !important;
					align-items: center;
				}
				td{
					padding-bottom: .5rem !important;
					&:last-child{

						order: 2;
					}
					&:first-child{
						order: 1;
						width: 100%;
					}
					&:nth-child(3){
						// order: -3;
						// width: 100%;
					}
				}
				tfoot{
					width: 100%;
					display: block;
					tr,
					td{
						width: 100%;
					}
				}
			}
				@media (max-width: 479.98px){
					td{
						&:nth-child(3) {
							// order: -3;
							width: 100%;
						}
					}
				}
			}
@media (max-width: 767.98px) {
	.block-p2p__head{

	}
}
.bid-head{

}
.bid-item{
	max-width: 100%;
	height: auto !important;

	.input-block {
		width: 100%;
	}
}


.bid-row  {
	// display: grid;
	display: flex;
	margin-left: -15px;
	margin-right: -15px;
	@media (min-width: 768px) {
		// grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 1281px) {
	}

	// grid-column-gap: 30px;
	// grid-row-gap: 30px;

	> .item {
		@media (min-width: 768px){
			width: calc((100%) / 2 - 30px);
		}
		@media (min-width: 1281px){
			width: calc((100%) / 3 - 30px);
		}
		width: 100%;
		max-width: 30em;
		min-width: 24rem;
		// margin: 0;
		margin-left: 15px;
		margin-right: 15px;
		// width: 100%;
	}
}

.bid-item {
	@media (max-width: 767.98px) {
	.sc-htoDjs{
			display: block;
			width: 100% !important;
		}
		.sc-krvtoX{
			&:not(:first-child){
				flex-wrap: wrap;
			}
			> * {
				margin-bottom: 0.5rem;
			}
		}
	}
}
.fw-500{
	font-weight: 500;
}

.order-aside{
	height: 100% !important;
	width: rem(458) !important;
	margin-left: 30px;
	max-width: 33%;
	padding-bottom: 0;
	margin-bottom: 30px;
	.h6{
		font-weight: normal;
		margin-bottom: 0;
	}
	.item{
		&:last-child{
			border: none;
		}
			@media (max-width: 767.98px){
				padding: 1rem 0;
			}
	}

		@media (max-width: 767.98px) {

		}
		@media (max-width: 991.98px) {
			margin-left: 0;
			max-width: 100%;
			width: 100% !important;
			order: -1;
			margin-bottom: 30px;
		}
		&__items{
			padding-bottom: 0;
			@media (max-width: 767.98px) {
				padding-top: 0rem;
			}
		}
		@media (min-width: 576px) and (max-width: 991.98px) {
					&__items{
						flex-direction: row;
					}
					.item{
						padding-top: 0;
						border: none;
						padding-left: 0;
						padding-right: 35px;
					}

	}
}


.order-main{
	flex-grow: 1;
	.btn-form{
		width: 100%;
		max-width: rem(240);
	}

	@media (max-width: 767.98px) {
		.row-block{
			display: block !important;
		}
		[width="25%"]{
			margin-bottom: 0.5rem;
			width: 100% !important;
		}
	}
		.tabs-control {
			text-align: center;
		@media (max-width: 767.98px) {
			// flex-direction: column;
			display: block;
			.outline{
				margin-top: 0;
				margin-bottom: 0 !important;
					height: rem(52) !important;
					border-radius: 0 !important;
					&:not(.active){

						border: 2px solid #e8e8e8;
					}
					&:first-child{
						border-radius: 5px 5px 0 0 !important;
					}
					&:last-child{
						margin-top: -1px;
						// border-left: 2px solid ;
						border-radius: 0 0 5px 5px  !important;
					}
				}
			}
		}
	}
	.controlWrap  {
		display: flex;
		align-items: center;
		height: 100%;
		@media (min-width: 768px) {
		padding-top: 0.4em;
		}
		flex-grow: 1;
		label{
			margin-bottom: 0 !important;
		}
	}
	@media (max-width: 767.98px) {
		.controlWrap  > label{
			margin-left: 0 !important;
		}
		.PageTitle{
			font-size: 20px !important;
			padding-bottom: 1.2rem !important;
			width: 100% !important;
		}
	}
	.coll{
		flex-grow: 1;
		@media (max-width: 767.98px){
				.row-block{
					display: block;
				}
				[width="33%"]{
					max-width: 100% !important;
					margin-bottom: 1rem;
				}
			}
		}
		.collapce-text{
			@include ellipsis;
			position: relative;
			top: rem(4);
			max-width: calc(100% - 50px);
			span{
				@include ellipsis;

			}
		}
@media (max-width: 767.98px) {
}
.select-currency{
	width: rem(160);
}
.last-operations {
	@media (max-width: 767.98px) {
		.btn-form{
			margin-top: 1rem;
		}
		> div{
			padding-left: 15px;
			padding-right: 15px;
		}
		}
	.mob-w100{
	@media (min-width: 768px) {
			order: 1;
		}
	}
	@media (max-width: 767.98px) {

		.mob-w100{
			margin-bottom: 0.5rem;
		}
		&__footer{
			display: block;
		}
	}
	.btn-form{
		@media (min-width: 768px){

			min-width: rem(270);
			margin-right: 30px;
		}
	}
	@media (max-width: 767.98px) {
		.row-block{
			display: block;
		}
		[width="33%"]{
			margin-bottom: 0.5rem;
			width: 100% !important;
		}
	}
}
	@media (max-width: 767.98px) {
	.mob-w50{
		width: 50% !important;
	}
	.mob-w100{
		width: 100% !important;
	}
}

.react-datetime-picker__wrapper{
	svg{
		stroke: #0078d7;
	}
}
.order-2{
	order: 2;
	@media (max-width: 820px) {

		[height="3.2vh"]{
			display: none;
		}
	}
}
.filter-top{
	@media (min-width: 768px) {
	.order-2{
		margin-left: auto;
	}
	}
	.btn-form {
		width: rem(180px) !important;
		margin-left: -1.125rem;
		order: 2;
	}

	@media (max-width: 767.98px) {
		.mob-w50{
			order: 1;
		}
		.react-datetime-picker{
			width: 100%;
		}
	.mob-w100{
			> div{
				margin-left: 0;
			}
			[width="10rem"]  {
				width: 100%;
		}
		}
}
}
.MobileMenuToggle {

	.MenuItemsList{
		// display: block;
		flex-wrap: wrap;
	}
	.MenuItem{
		// display: block;
		display: flex;
		width: 100%;
		position: relative;
		border-bottom: 1px solid #e8e8e8;
		padding-left: rem(62);
		img{
			// position: absolute;
			// l
		}
	}
	.icon-wrap{
		position: absolute;
		width: 30px;
		left: 15px;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
	}
}


.menuFooter{
		@media (min-width: 768px){

			display: none;
		}
		.MenuItemsList{
			justify-content: space-between;
			min-width: 1px !important;
			margin-left: auto;
			margin-right: auto;
			max-width: 440px;
			padding-left: 15px;
			padding-right: 15px;

		}
	position: fixed;
	width: 100%;
	// max-width: 100vh;
	left: 0;
	bottom: 0;
	background-color: #ffffff;
	z-index: 100;
	.MenuItem{
		padding: 0;
		text-align: center;
		display: flex;
		justify-content: center;
		width: auto;
		max-width: 40px;
		padding: 5px;
		flex-grow: 1;
			border-top: 2px solid transparent;

			&.active {
				border-color: #0098db;
			}
	}
	img{
		@include mt;
		filter: saturate(0);
		&:hover{

			filter: saturate(1);
		}
	}
}





.pageTitle {
	font-weight: 500;
	white-space: normal !important;
}

.referrals-main {
	@media (min-width: 1100px){

		max-width: calc(100% - 33%) !important;
		flex-grow: 1;
		order: 0 !important;
	}

}
.referrals-aside {
	height: 100% !important;
	@media (min-width: 1100px){

		width: rem(458) !important;
		margin-left: 30px;
		max-width: 33% !important;
	}
	width: 100% !important;
	padding-bottom: 0;
}

.mb-30{
	margin-bottom: rem(30);
}

.promocode-block{
		@media (max-width: 767.98px) {
			.ControlsWrap{
				flex-wrap: wrap;
				> div{
					&:nth-child(1){

						width: calc(55% - 1rem);
					}
					width: calc(45% - 1rem);
					min-width: 1px;
					margin-bottom: 1rem;
				}
			}
			.btn-box{
				width: 100% !important;
				margin-left: 0 !important;
				[height="3.75rem"]{
					width: 100%;
				}
			}
		}
		@media (max-width: 767.98px) {
				&:nth-child(1) {

					margin-bottom: 20px;
				}
		}
		@media (min-width: 768px) {

			max-width: calc(45% - 15px);
			width: 100%;
			flex: 0 0 calc(45% - 15px);
			min-height:  rem(315);
			&:nth-child(1){
				margin-bottom: 0;
				max-width: calc(55% - 15px);
				flex: 0 0 calc(55% - 15px);
				margin-right: 30px !important ;
			}
		}

		width: 100% !important;
	min-width:  1px !important;
}


.promocode-main{
	> div{
		align-items:inherit !important;
		flex-wrap: wrap;
	}
	.crypto-select {

		[height="3.75rem"] {
			padding-left: .6rem;
			&.selected{
				// padding-left: 0;
			}
			&.active{
				padding-left: 0;

			}
		}
	}
	table{
		@media (min-width: 1100px){
			th,
			td{
				font-size: 0.9em !important;
				// padding: 0px 1.2rem !important;
				height: auto;
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4){
					display: none !important;
				}
			}
		}
	}
		@media (max-width: 1100px){
			thead,
			th{
				display: none !important;
			}
			table{
				display: block;
				height: auto !important;
			}


					.ClipText {
						width: initial !important;
								}
								tbody{
									display: block;
								}
			tr{
				display: block;
				height: auto !important;
				display: flex;
				flex-wrap: wrap;
				position: relative;
					background-color: #f5f5f5;
					border-radius: $border-radius;
					margin-bottom: 5px;
					border: 2px solid #e8e8e8;
			}
			td{
				display: flex;
				height: auto !important;
				display: block;
				font-weight: 500;
				&:nth-child(1){
					display: none;
				}
				&:nth-child( 2),
				&:nth-child( 3),
				&:nth-child( 4) {
						padding: rem(16) rem(12) !important;
				}
				&:nth-child( 4) {
					position: relative;
					padding-right: rem(20);
					flex-grow: 1;
					&::after {
						content: url(../img/chevron-down.svg);
						// position: relative;
						position: absolute;
						margin-left: auto;
						right: 10px;
					}
				}
				&:nth-child(5) {
					border-top: 2px solid #e8e8e8 !important;
					&::before {
						content: 'Промокод: '
					}
				}

				&:nth-child(6) {
					&::before {
						content: 'Дата создания: '
					}
				}

				&:nth-child(7) {
					&::before {
						content: 'Валюта: '
					}
				}

				&:nth-child(8) {
					&::before {
						content: 'Номинал: '
					}
				}
				&:nth-child(9) {
					&::before {
						content: 'Доступно:  '
					}
				}
				&:nth-child(10) {
					&::before {
						content: 'Состояние: '
					}
				}
				&:nth-child(11) {
					position: absolute;
					right: 0px;
					top: 50px;
					width: auto !important;
					svg{
						width: 2rem !important;
						height: 2rem !important;
					}
				}

				&:nth-child(n + 5) {
					// display: flex;
					display: none;
					padding: rem(10) rem(12) !important;
					width: 100%;
					flex-wrap: wrap;

					&::before {
						margin-right: 10px;
						font-weight: bold;
					}
				}
			}

			tr.active td {

				&:nth-child(4) {
					&::after {
						transform: rotateX(180deg);
					}
				}

				&:nth-child(n + 5) {

					display: flex !important;

				}
			}
			tfoot {
				display: block;
				width: 100%;

				td,
				tr {
					display: block !important;
					width: 100%;
					padding: 0 !important;
				}
			}
								tfoot {
									display: block;
									width: 100%;

									td,
									tr {
										padding: 0 !important;
										display: block;
										width: 100%;
									}
								}
		}
}

[height="3.8em"] {
	img{
		margin-left: -.5rem !important;
	}
}

.minimized .menu-root-inner {
	width: 70px;
}
.menu-root-inner{
	background-color: #ffffff;
	// position: relative;
	position: fixed;
	left: 0;
	top: 0;
	width: 18.4rem;
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 1000;
}
.arrow-text{
	margin-left: 1em;
}


.filter-p2p{


}


button{
	outline: none !important;
}

.MuiTableCell-root{
	> div{
		position: relative;
	}
	.select-icon-wrap{
		left: rem(0) !important;
		margin-top: rem(-2) !important;
		@media (min-width: 1100px) {
		}
	}
}
.maintenance-block{
	svg{

		width: rem(30) !important;
	}
	form{
		flex-grow: 1;
		input{
			width: 100%;
			margin-left: 0.5rem !important;
		}
	}
}
.add-main{
	margin-bottom: 30px;
	svg{
		width: rem(20);
	}
}
.adaptive-main {
	@media (max-width: 1080px) {
		flex-direction: column;
	}
}

.wrap-tablet{
	margin-bottom: 30px;
}

.pageSetting{
	@media (max-width: 767.98px) {
		.tabs-control{
			display: block;
			// padding: 0 2.1rem;
			margin-left: -2.1rem;
			margin-right: -2.1rem;
			> div{
				width: 100% !important;
				margin: 0 !important;
			}
		}
	}
	.row-block{
		max-width: rem(652);
			@media (max-width: 767.98px) {
				display: block;
				[width="100%" ]{
					margin-top: rem(5);
				}
			}
			.inputTitle{
				display: block;
				max-width: rem(250);
				align-self: center;
				margin-bottom: 2rem;
				@media (max-width: 767.98px){
					margin-bottom: 1rem;

					}
					width: 100%;
					min-width: 1px !important;
					+ div{
						flex-grow: 1;
					}
			}
	}
	> span,
	[width="24.625rem"]{
		max-width: rem(250);
		width: 100%;
		min-width: 1px !important;
	}
}


[height="65vh"]{
	form{
		flex-grow: 1;
		input{
			width: 100%;
			margin-left: 0.5rem !important;
		}
	}
}

.deal-aside{

}

.ReferralBonuses{
	flex-wrap: wrap;
}
.hasCopy{
	max-width: 100%;
}

.btn-form{
	text-align: center;
}
@media (max-width: 768px){

	.addr-row{
		width: 100%;
	}
}
.addr-wrap {
	// display: inline-block;
	max-width: calc(100% - 82rem);
	 span{
		max-width: 100%;
		display: block;
		white-space: nowrap;
		text-overflow:ellipsis;
		overflow: visible;
		// white-space: normal !important;
		// word-wrap: break-word;
	}
}

.order-row{
	form{
		flex-grow: 1;
		input{
			flex-grow: 1;
			width: 100%;
			margin-left: 0.5rem !important;
		}
	}
}

.right-block{
	.order-aside{
		// margin: 0;
		margin-bottom: 0;
		width: 100% !important;
		max-width: 100% !important;
		margin-left: 0 !important;
	}
}
.right {
	@include mt;
}
.minimized{
	 .right {
	 	position: relative;
	 	// right: rem(-15);
	 }
}

.block-p2p{
	margin-bottom: 30px;
}

 .select-icon-wrap {
	img{
		height: 1.2rem !important;
	}
}
.right-block .select-icon-wrap {
	img{
		height: 1.875rem !important;
	}
}

.bid-head .PageTitle{
	// margin-right: rem(30);
	padding-right: rem(30);
}
.tab-g1{
	@media (min-width: 820px) {
	&:nth-child(4) {

		padding-right: 15px;
	}
	}

}
	@media (min-width: 768px) {
		.typeF{
		 min-width: rem(200);
		 max-width: rem(200);
		}
		.tab-g1{
			flex-grow: 1;
			&:nth-child(4){

				// padding-right: 15px;
			}
			.react-datetime-picker{
				display: flex;
				// min-width: rem(250px);
			}
		}
	}
	.react-datetime-picker__inputGroup__input:invalid {
		background: none !important;
		outline: none !important;
	}

		.minimized .right {}

		.minimized .arrow-text {
			margin-left: 0rem;
			width: 1px;
		}
		.minimized .arrow-circle {
				position: relative;
				left: rem(12) !important;
			@media (min-width: 1281px) {
				left: rem(-2) !important;
			}
				@media (min-width: 1440px) {
					left: rem(-12) !important;
				}
}

.hidden-tablet .order-aside{
	margin-bottom: 0;
}



	@media (max-width: 767.98px) {
		.order-row{
			// margin-bottom: 30px;
			display: block !important;
			[height="50vh"]{
				min-width: 1px !important;
			}
		}
		body .wrap-tablet tfoot {
			td:nth-child(1) {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}
		.MobileMenu__body{

			[width="3.75rem"]{
				margin-left: .3rem !important;
			}
		}
		.border-bottom{
			margin-right: -15px;
			padding-right: 15px;
		}
	}
	.MobileMenu__body {
		[height="3.75rem"]{

			// border-bottom: 1px solid #e8e8e8 !important;
			// margin-right: rem(-15);
		}
	}
	.mobileProfile{
		border-bottom: 1px solid #e8e8e8 !important;
		margin-right: rem(-15);
		padding-right: rem(15);
	}

	.react-datetime-picker__inputGroup{
		.react-datetime-picker__inputGroup__day ,
		.react-datetime-picker__inputGroup__month
		{
			min-width: rem(40) !important;
			text-align: center;
			// font-size: rem(24) !important;
		}
		.react-datetime-picker__inputGroup__year {
			width: rem(60) !important;
			text-align: center;
			// font-size: rem(24) !important;
		}
	}
@media (max-width: 767.98px) {
	.MuiDialog-paperWidthFalse,
	.MuiDialog-paperScrollPaper {
		max-width: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
		padding-bottom: 1rem !important;
		[width="14.625rem"]{
			width: 150px !important;
		}
	}
	[width="293px"],
	[width="22vh"]{
		width: 100% !important;
	}
	[width="15vh" ]{
		width: calc(50% - 1rem) !important;
		display: inline-flex;
	}
	.btn-wrap-flex{
		justify-content: space-between;
	}
	[width="60vh"]{
		max-width: 100% !important;
		width: 100% !important;
	}
	.fastDeal-row{
		display: block !important;
		margin-left: auto;
		margin-right: auto;
		max-width: rem(370);
	}
	.deal{
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	.mobile-d-block{
		display: block !important;
		>[color="#808080"] {
			display: block;
		}
	}
	.deal-btn-wrap{
		display: block !important;
		text-align: center !important;
		 > div{
			margin-left: 0 !important;
			margin-right: 0 !important;
			margin-bottom: 1rem;
		}
	}
	.dealText{
		max-width: 100%;
		width: 100% !important;
	}

	.deal .tabs-control{
		margin-left: 0 !important	;
		text-align: center;
		span{
			font-size: rem(14);
		}
		// font-size: 10px;
	}
}

.mobile-d-block {
	>[color="#808080"] {
	 margin-right: 5px;
	}
}
.message{
	img{
		max-width: 100%;
		height: auto !important;
		max-height: 10rem;
	}

}

.deal{

}

.MuiSvgIcon-root{

		position: relative;
		top: rem(-2);
}

.react-datetime-picker{
		height: rem(60);
}

.MuiIconButton-label{
	font-size: 1.25rem;
}

.MuiSvgIcon-root{
	font-size: 1.8rem !important;
}

.notificationsItem{
	@media (min-width: 768px) {
		height: 63vh;
	}
}
.react-datetime-picker__wrapper {
	padding-left: rem(10);
}
.react-datetime-picker__inputGroup__leadingZero{
	// position: relative;
	position: absolute;
	top: rem(19);
	margin-left: rem(6);
	// left: rem(5);
}
.react-datetime-picker__inputGroup__input--hasLeadingZero {
	// margin-left: -0.5em;
	// margin-left: 0;
	// padding-left: 0;
	// padding-left: calc(1px + 0.8em);
}
@media (max-width: 767.98px) {

	.FilterBox-head{
		> div{
			width: 100% !important;
			margin-left: 0 !important;
		}
		[width="10rem"],
		.react-datetime-picker{
			width: 100% !important;
		}
	}
	.controlWrap{
		margin-bottom: 1rem;
	}
	.lang-select-option span{
		display: none;
	}
	.home-select.lang-select{
		width: 2.4rem !important;
		img{
			height: 1.2em !important;
		}
	}
	.top-nav__logo{
		svg{
			margin-right: rem(10) !important;
		}
	}
}

.coll{
	max-width: 100%;
}
.addr-row{
	svg{

		flex: 0 0 auto;
	}
}
.addr-wrap{

	color: #0098db;
	margin-left: 0.5rem;
	display: block;
	flex-grow: 1;

	max-width: 100%;
	display: block;
	white-space: nowrap;
	text-overflow:ellipsis;
	overflow: hidden;
}

.mainBlock{
	 flex-grow: 1;
	 height: 100%;
	 //padding: 0 15px;
	 width: 80%;
	 padding-right: 15px;
	 max-width: 1092px;
	 padding-left: 15px;
	 margin-left: auto;
	 margin-right: auto;
	@media (max-width: 767.69px) {
		padding-bottom: 9rem;
	}

	@media (min-width: 768px) {

		padding-left: 85px;
	}

	@media(min-width: 1281px) {
		padding-left: 30px;
		max-width: 1501px;
	}

	@media(min-width: 1520px) {
		padding-left: 29px;
	}
}

.TopBar .popover{
	[height="3.75rem"]{
		height: auto !important;
		min-height: 3.75rem;
	}
}

.referrals-aside{
	.select-icon-wrap img {
		height: 1.875rem !important;
		flex-shrink: 0;
	}
}


.deals-table{
	table{
		max-width: 100%;
	}
	th{
		&:last-child{
			text-align: center;
		}
	}
	td,
	th{
		padding-right: rem(10) !important;
		@media (max-width: 1240.69px) {
			padding-left: rem(10)  !important;
			min-width: 1px !important;
			white-space: nowrap;

		}

	}
	@media (max-width: 1240.69px){
		table{

			display: block;
			// margin: -1.875rem;
			// width: calc(100% + 2 * 1.875px);
		}
		thead{
			display: none;
		}
		tbody,
		td{
			display: block;
		}
		td{
			margin-bottom: 10px       ;
			width: 100%;
			&:nth-child(2){
				&::before{
					content: 'Покупатель: ';

				}
			}
			&:nth-child(6){
				&::before{
					content: 'Продавец: ';

				}
			}
		}
		tr{
			display: block;
			height: auto !important;
			border-bottom: 1px solid #e8e8e8;
			padding: rem(20);
			margin: 0 -1.875rem ;
			background: none   !important;

		}
		tfoot{
			display: block;
		}

	}


}
table .MuiSvgIcon-root{
	width: 0.8rem;
	height: .8rem;
	@media (max-width: 1240.69px) {
		display: none;
	}
}
